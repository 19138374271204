// import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import Pics from "@assets";
import DummyImage from "@assets/dummyImage.png"

const ImageCard = (({ i, fileHandler, img, classes, type }) => {

  const [image, setImage] = React.useState(null)
  React.useEffect(() => {
    setImage(img)
  }, [img])

  return (
    <Box
      className={classes.pic}
      style={{
        backgroundImage:
          (type === "image/*" &&
            image !== '/static/media/dummyImage1.a829ff0a.svg'
          ) ? `url('${image}')` : null,
      }}
    >
      <Box className={classes.picContainer}>
        {type === "video/*" && (
          <img
            alt="icon"
            src={Pics.videoThumbnail}
            style={{ position: "absolute", top: 20, left: 30, height: 24 }}
          />
        )
        }
        {
          image === '/static/media/dummyImage1.a829ff0a.svg'
          &&
          <img
            alt="icon"
            src={image}
            style={{ position: "absolute", top: 20, left: 30, height: 24, opacity: 0.1 }}
          />
        }
        <label htmlFor={i}>
          <Typography
            className={
              // img && img !== `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`
              image && image !== { DummyImage }
                ? classes.changePicButton
                : classes.otherChangePicButton
            }
          >
            {type === "image/*" ? (
              <img alt="icon" src={"./assets/images/dummyImage.svg"} width={11} />
            ) : (
              <img alt="icon" src={Pics.videoPlaceholder} width={11} />
            )}
            {"  "}
            {/* {img && img !== `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png` */}
            {image && image !== { DummyImage }
              ? "Change"
              : "Add"}
          </Typography>
        </label>
        <input
          accept={type}
          onChange={(e) => fileHandler(e, i)}
          style={{ display: "none" }}
          id={i}
          type="file"
        />
      </Box>
    </Box>
  );
})

export default ImageCard
import React from "react";
import { Box, Typography, InputAdornment } from "@material-ui/core";
import InputField from "@base/InputField";
const InputGroup = ({ yearsProps, expertiseProps, ...props }) => {
	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="end"
			width="100%"
			marginBottom="10px"
			minHeight="50px"
		>
			<div style={{ marginRight: "10px", alignSelf: "center" }}>
				<Typography variant="subtitle2">{props.number}</Typography>
			</div>
			<div style={{
				width: "60%",
				marginTop: "10px",
			}}>
				<InputField
					errorIconShow={false}
					name={expertiseProps.name}
					error={expertiseProps.error}
					disabled={expertiseProps.disabled}
					onChange={(name) => (e) =>
						expertiseProps.setFieldValue(expertiseProps.name, e.target.value)}
					onBlur={expertiseProps.handleBlur}
					onFocus={expertiseProps.onFocus}
					value={expertiseProps.value}
					maxLength="46"
				/>
				{/* <div>err</div> */}
			</div>
			<div style={{ width: "25%", color: "#FFFFFF", display: "flex" }}>
				<div style={{ height: "20px" }}></div>
				<div style={{ marginTop: '9px' }}>
					<InputField
						errorIconShow={false}
						removeUnderline
						type="number"
						textAlign="center"
						onBlur={yearsProps.handleBlur}
						error={yearsProps.error}
						name={yearsProps.name}
						value={yearsProps.value}
						disabled={yearsProps.disabled}
						paddingNone
						onChange={(name) => (e) => {
							// alert(Number(e.target.value )=== 2)
							if (e.target.value === "") {
								yearsProps.setFieldValue(yearsProps.name, e.target.value);
								return
							}
							if (Number(e.target.value) < 1 || Number(e.target.value) > 45) {
								// alert("fine");
								return;
							}
							yearsProps.setFieldValue(yearsProps.name, e.target.value);
						}}
						isExperties="Yes"
						showBorder="No"
						startAdornment={
							<span style={{ border: '1px solid white', padding: '20% 0% 20% 10%', borderRadius: '8px 0px 0px 8px', cursor: "pointer", borderTop: 'none', borderBottom: 'none' }}
								onClick={() => {
									if (yearsProps.disabled) {
										return;
									}
									if (Number(yearsProps.value) === 1) {
										return;
									}
									let update = Number(yearsProps.value) - 1;
									yearsProps.setFieldValue(yearsProps.name, Number(update));
								}}>
								<InputAdornment position="start">
									<div
										// onClick={() =>
										//   years <= 0 ? console.log("disable") : setCounter("dec")
										// }
										onClick={() => {
											if (yearsProps.disabled) {
												return;
											}
											if (Number(yearsProps.value) === 1) {
												return;
											}
											let update = Number(yearsProps.value) - 1;
											yearsProps.setFieldValue(yearsProps.name, Number(update));
										}}
										style={{ color: "#FFFFFF", cursor: "pointer" }}
									>
										-
									</div>
								</InputAdornment>
							</span>
						}
						endAdornment={
							<span style={{ border: '1px solid white', padding: '20% 10% 20% 0%', borderRadius: '0px 8px 8px 0px', cursor: "pointer", borderTop: 'none', borderBottom: 'none' }}
								onClick={() => {
									if (yearsProps.disabled) {
										return;
									}
									if (Number(yearsProps.value) >= 50) {
										return;
									}
									let update = Number(yearsProps.value) + 1;
									yearsProps.setFieldValue(yearsProps.name, Number(update));
								}}>
								<InputAdornment position="end">
									<div
										// onClick={() => setCounter("inc")}
										onClick={() => {
											if (yearsProps.disabled) {
												return;
											}
											if (Number(yearsProps.value) >= 50) {
												return;
											}
											let update = Number(yearsProps.value) + 1;
											yearsProps.setFieldValue(yearsProps.name, Number(update));
										}}
										style={{ color: "white", cursor: "pointer" }}
									>
										+
									</div>
								</InputAdornment>
							</span>
						}
					/>
				</div>
			</div>
		</Box>
	);
};
export default React.memo(InputGroup);

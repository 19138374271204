import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Pics from "@assets/index";
import StarRateIcon from "@material-ui/icons/StarRate";
import DummyImage from "@assets/dummyImage.png"

const useStyles = makeStyles((theme) => ({
	container: {
		height: "150px",
		width: "150px",
		backgroundSize: "contain",
		borderRadius: "6px"
	},
	tags: { marginRight: "10px", fontSize: 10, fontFamil: "cerebri-regular" },
	title: {
		backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
		position: "absolute",
		width: "100%",
		bottom: "0",
		padding: "5px"
	},
	tagsContainer: {
		display: "flex",
		flexDirection: "row",
		margin: "10px 0 0 0",
		width: "150px",
		flexWrap: "wrap",
		overflow: "auto",
	},
	cardContainer: {
		height: "150px",
		width: "150px",
		backgroundColor: "rgba(0,0,0,0.4)",
		position: "relative",
	},
	textContainer: {
		backgroundImage: "linear-gradient(#0A0A0A, #00000000)",
		padding: "5px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},
}));
function UserCard({ user, ...props }) {
	const classes = useStyles();
	// console.log("USER", user)
	// const [firstExpertise, setFirstExpertise] = useState(null);
	// const [firstExpertiseExp, setFirstExpertiseExp] = useState(null);
	// useEffect(() => {
	// 	if(user && user.tags){
	// 		if(!user.tags[0].expertise) {
	// 		}
	// user.tags[0].expertise ? setFirstExpertise(user.tags[0].expertise) : setFirstExpertise(null);
	// setFirstExpertiseExp(user.tags[0].years);
	// 	}
	// }, [user])
	return (
		<Box
			className="userCard disable-selection"
			style={{
				margin: props.fromHomeScreen ? "10px 0 10px 0px" : "10px 0",
				cursor: 'pointer',
			}}
		>
			<Paper
				className={classes.container}
				style={{
					// backgroundImage: `url('${user.profile_media_urls[0]}')`,
					backgroundImage: user.profile_media_urls[0] === "https://narsunprojects.com:3001/uploads/default_pic.png" ? `url('${DummyImage}')` : `url('${user.profile_media_urls[0]}')`,
					backgroundSize: '100% 100%',
					backgroundRepeat: 'no-repeat',
					// border: "1px dashed orange"
				}}
			>
				<Box className={classes.cardContainer}>
					<Box className={classes.textContainer}>
						<Box display="flex" flexDirection="row" alignItems="center">
							<StarRateIcon style={{ color: "#FCBD3F" }} />
							<Typography variant="body1" style={{ fontSize: user.rating == 0 ? "12px" : '18px' }} >{user.rating == 0 ? 'No Reviews' :  user.rating.toFixed(1)}</Typography>
						</Box>
						<Typography variant="body1">
							{/* {user.rate <= 0 ? "Free" : `$${user.rate.toFixed(2)}`} */}
							{user.rate <= 0 ? "Free" : `$${user.rate}`}
						</Typography>
					</Box>
					<Typography variant="body1" className={classes.title}>
						{user.enabledUsername ? user.username : user.full_name}
					</Typography>
				</Box>
			</Paper>
			<Box className={classes.tagsContainer}>
				{
					user.tags.length ?
						<Typography variant="caption" className={classes.tags}>
							{user.tags[0]?.expertise} ({user.tags[0]?.years} {user.tags[0]?.years === 1 ? 'year' : 'years'})
						</Typography>
						: null
				}
				{/* {user.tags?.map((t, i) => (
					<Typography variant="caption" key={i} className={classes.tags}>
						{t.expertise}({t.years})
					</Typography>
				))} */}
			</Box>
			{/* <Box className={classes.tagsContainer} style={{ width: '100%' }}>
				<Typography variant="caption" className={classes.tags} style={{ width: '80%' }}>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<p style={{ wordBreak: "break-all" }}>{firstExpertise}</p>
						<p>({firstExpertiseExp})</p>
					</div>
				</Typography>
			</Box> */}
		</Box>
	);
}

export default React.memo(withRouter(UserCard));

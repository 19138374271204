import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const months = [
  {
    value: "January",
    label: "01",
  },
  {
    value: "February",
    label: "02",
  },
  {
    value: "March",
    label: "03",
  },
  {
    value: "April",
    label: "04",
  },
  {
    value: "May",
    label: "05",
  },
  {
    value: "Jun",
    label: "06",
  },
  {
    value: "July",
    label: "07",
  },
  {
    value: "August",
    label: "08",
  },
  {
    value: "September",
    label: "09",
  },
  {
    value: "October",
    label: "10",
  },
  {
    value: "November",
    label: "11",
  },
  {
    value: "December",
    label: "12",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "cerebri-regular",
    fontSize: "18px",
    "& > *": { color: "white" },

    "&:before": {
      borderBottom: ".5px solid #808080",
    },

    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  underline: {
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  inputBase: {
    paddingLeft: "8.5px",
    paddingRight: "8.5px",
    paddingTop: "13px",
    height: "16px",
  },
  label: {
    fontSize: "50px",
  },
}));

export default function DropDown(props) {
  const classes = useStyles();
  const [date, setDate] = React.useState({});

  const handleChange = (name) => (event) => {
    setDate((pre) => ({ ...pre, [name]: event.target.value }));
  };

  React.useEffect(() => {
    setDate({ year: "", day: "", month: "" });
  }, []);
  React.useEffect(() => {
    date.day && date.month && date.year && props.onDateChange("dob", date);
  }, [date.day, date.month, date.year, date]);

  return (
    <div>
      <Typography variant="subtitle2">{"Date of birth"}</Typography>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <TextField
          color="secondary"
          id="dob"
          select
          value={date.month}
          style={{ width: "50%" }}
          InputProps={{
            classes: {
              root: classes.root,
              underline: classes.underline,
              input: classes.inputBase,
            },
          }}
          onChange={handleChange("month")}
        >
          {months.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          color="secondary"
          id="day"
          value={date.day}
          type="number"
          inputProps={{ style: { textAlign: "right" }, maxLength: 2 }}
          style={{ width: "16%" }}
          InputProps={{
            classes: {
              root: classes.root,
              underline: classes.underline,
              input: classes.inputBase,
            },
          }}
          onChange={handleChange("day")}
        />
        <TextField
          color="secondary"
          id="year"
          value={date.year}
          style={{ width: "23%" }}
          inputProps={{ style: { textAlign: "right" }, maxLength: 4 }}
          // type="number"
          InputProps={{
            classes: {
              root: classes.root,
              underline: classes.underline,
              input: classes.inputBase,
            },
          }}
          onChange={handleChange("year")}
        />
      </Box>
    </div>
  );
}

import React from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import { ListItem, ListItemText, ListItemAvatar, Avatar, Typography, makeStyles } from '@material-ui/core'
// import { FullscreenExitTwoTone } from '@material-ui/icons'
const StyledListItem = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
  }
})(ListItem)
const StyledListItemText = withStyles({
  primary: {
    fontSize: 16,
    fontWeight: 600,
  },
  secondary: {
    color: '#808080',
    fontSize: 12,
  }
})(ListItemText)
const StyledInviteText = withStyles({
  root: {
    color: '#808080',
    fontSize: 18,
    fontFamily: 'cerebri-regular',
  }
})(Typography)
const useStyles = makeStyles(() => ({
  sideDiv: {
    display: 'flex',
    flexDirection: 'column',
    color: '#808080',
    height: 50,
    alignItems: 'center'
  },
  icon: {
    height: 20,
    width: 20
  },
  text: {
    color: '#808080',
  }
}))
export default function InviteListItem({ list }) {
  const classes = useStyles();
console.log(list)
  return (
    <div>
      {list?.users?.map(e =>
      (<StyledListItem>
        <ListItemAvatar>
          <Avatar
            variant='rounded'
            src={e.profile_media_urls[0]}
          />
        </ListItemAvatar>
        <StyledListItemText primary={e.enabledUsername ? e.username : e.full_name} secondary={moment(e.created_at).format('MMM, DD [at] h:mmA')} />
        {/* <div className={classes.sideDiv}>
        <img src='/assets/Tabbar/TabbarInactive/clock.png' className={classes.icon} />
        <StyledInviteText variant='h6'>Pending</StyledInviteText>
      </div> */}
      </StyledListItem>)
      )}
    </div>
  )
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "@base";
import { withRouter } from "react-router";
import queryString from "query-string";
import Axios from "axios";
import {
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { InputField } from "@base";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "./store/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import MyDrawer from "./subComponents/drawer";
import { isIPad13, isMobile, isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";
import SocketClass from "@utils/socketService";
let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
let socket = undefined;
let check = false;
const months = [
  {
    value: "January",
    label: "01",
  },
  {
    value: "February",
    label: "02",
  },
  {
    value: "March",
    label: "03",
  },
  {
    value: "April",
    label: "04",
  },
  {
    value: "May",
    label: "05",
  },
  {
    value: "June",
    label: "06",
  },
  {
    value: "July",
    label: "07",
  },
  {
    value: "August",
    label: "08",
  },
  {
    value: "September",
    label: "09",
  },
  {
    value: "October",
    label: "10",
  },
  {
    value: "November",
    label: "11",
  },
  {
    value: "December",
    label: "12",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "cerebri-regular",
    fontSize: "18px",
    "& > *": { color: "white" },
    "&:before": {
      borderBottom: ".5px solid #808080",
    },
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  underline: {
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  inputBase: {
    paddingLeft: "8.5px",
    paddingRight: "8.5px",
    paddingTop: "13px",
    height: "16px",
  },
  label: {
    fontSize: "50px",
  },
  menuItem: {
    background: "#676565",
    color: theme.palette.secondary.main,
    fontFamily: "cerebri-semibold",
    "&:hover": {
      // fontFamily:'cerabri-semibold',
      backgroundColor: "lightGrey",
      color: theme.palette.primary.main,
    },
    "&:before": {
      display: "none",
    },
  },
  btns: {
    cursor: "pointer",
  },
  wrapper: {
    // border: "1px dashed orange",
    height: isMobile ? "555px" : "auto",
    // minHeight: isMobile ? "560px" : "auto",
    // height: "100%",
    overflowY: "scroll",
  },
  btnBottom: {
    width: isTablet || isIPad13 ? "375px" : "100%",
    "@media (max-height: 813px)": {
      position: "absoulte",
      bottom: "15px",
    },
  },
}));
const SignupForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [hideButton, setHideButton] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    socket = SocketClass.getSocket();
  }, []);

  const signupResponse = useSelector(
    ({ Signup }) => Signup.SubmitSignupReducer
  );

  const { layout } = useSelector(({ BaseReducer }) => ({
    layout: BaseReducer.LayoutReducer,
  }));

  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);

  React.useEffect(() => {
    if (layout.bottomNav)
      dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, [layout.bottomNav]);

  const signupResponseData = signupResponse.data;
  const errMsg = signupResponse.errMsg;

  React.useEffect(() => {
    const id = queryString.parse(props.location.search).id;
    const code = queryString.parse(props.location.search).code;
    const arr = [];

    if (signupResponseData) {
      console.log("inside", signupResponseData.status, check);
      if (signupResponseData.status === "Success" && check == false) {
        setOpen(true);
        check = true;
        handleSave();
        history.push("/home");
      }
    }
  }, [userData]);

  function getQuartersStamp(current) {
    let arr = [];

    const hours = new Date().getUTCHours(); // setting UTC + 5 OFFSET , BY DEFUALT ITS GIVING +8
    const calc = hours % 24; //convert to 24hr format if exceeds 24 bcz of utc hours exceed 24

    console.log("Before Calc", calc);
    console.log("Calculated Time", 11 - calc + 1);

    if (calc + 5 < 9) {
      //current time < 9
      for (let i = 0; i < 11 - calc + 1; i++) {
        let nestedArray = [];
        let lDate = new Date(current).toLocaleDateString("en-US");
        let lTime = new Date(current).toLocaleTimeString();
        let params = lDate.split("/").reverse();
        params = [...params, ...lTime.split(":")];

        if (i == 0) {
          //if time is less than or equal to 00
          if (Number(params[4]) <= 0)
            nestedArray.push(
              new Date(
                Number(params[0]), //year
                Number(params[2]) - 1, //month
                hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
                9 + i + 5, //hours
                0 //minutes
              ).toISOString()
            ); // 00:00

          if (Number(params[4]) <= 15)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
                9 + i + 5,
                15
              ).toISOString()
            ); // 00:15

          if (Number(params[4]) <= 30)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
                9 + i + 5,
                30
              ).toISOString()
            ); // 00:30

          if (Number(params[4]) <= 45)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
                9 + i + 5,
                45
              ).toISOString()
            ); // 00:45
        } else {
          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              9 + i + 5,
              0
            ).toISOString()
          ); // 00:00

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              9 + i + 5,
              15
            ).toISOString()
          ); // 00:15

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              9 + i + 5,
              30
            ).toISOString()
          ); // 00:30

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              9 + i + 5,
              45
            ).toISOString()
          ); // 00:45
        }

        arr.push(nestedArray);
      }
    }
    //current time between 9-5
    else if (calc + 5 > 9 && calc + 5 < 17) {
      for (let i = 0; i < 11 - calc + 1; i++) {
        let nestedArray = [];
        let lDate = new Date(current).toLocaleDateString("en-US");
        let lTime = new Date(current).toLocaleTimeString();
        let params = lDate.split("/").reverse();
        params = [...params, ...lTime.split(":")];

        console.log("Parms-->", params);
        console.log(
          "Params- Dest --->",
          params[0],
          params[1],
          params[2],
          params[3]
        );

        if (i == 0) {
          //if time is less than or equal to 00
          if (Number(params[4]) <= 0)
            nestedArray.push(
              new Date(
                Number(params[0]), //year
                Number(params[2]) - 1, //month
                Number(params[1]), //day
                calc + i + 5, //hours
                0 //minutes
              ).toISOString()
            ); // 00:00

          console.log(
            "Date-->",
            new Date(
              Number(params[0]), //year
              Number(params[2]) - 1, //month
              Number(params[1]), //day
              calc + i + 5, //hours
              0
            ).toISOString()
          );

          if (Number(params[4]) <= 15)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                Number(params[1]), //day
                calc + i + 5,
                15
              ).toISOString()
            ); // 00:15

          if (Number(params[4]) <= 30)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                Number(params[1]), //day
                calc + i + 5,
                30
              ).toISOString()
            ); // 00:30

          if (Number(params[4]) <= 45)
            nestedArray.push(
              new Date(
                Number(params[0]),
                Number(params[2]) - 1,
                Number(params[1]), //day
                calc + i + 5,
                45
              ).toISOString()
            ); // 00:45
        } else {
          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              Number(params[1]), //day
              calc + i + 5,
              0
            ).toISOString()
          ); // 00:00

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              Number(params[1]), //day
              calc + i + 5,
              15
            ).toISOString()
          ); // 00:15

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              Number(params[1]), //day
              calc + i + 5,
              30
            ).toISOString()
          ); // 00:30

          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              Number(params[1]), //day
              calc + i + 5,
              45
            ).toISOString()
          ); // 00:45
        }

        arr.push(nestedArray);
      }
    } else {
      //current time > 5
      console.log("do nothing");
    }

    return [arr.flat(), 11 - calc + 1];
  }

  const getDate = () => {
    const current = new Date();
    const reqDates = getQuartersStamp(current);
    return reqDates;
  };

  const handleSave = async () => {
    //gets all the dates, according to the selected, e.g: 1hr = 00,15,30,45
    const dates = getDate();

    dispatch(BaseActions.showLoading(true));

    socket.emit(
      "updatemyprofile",
      { hours: Number(dates[1]), available: 1 },
      (payload) => {
        console.log("acknowledgment", payload);
      }
    );

    const payloads = dates[0].map((date) => {
      let stampObject = {
        appointment: true,
        attendee: false,
        date: date,
        forced: false,
        quartr_now: false,
        status: false,
        timestamp: date,
      };
      return stampObject;
    });
    // enable actions here
    console.log(userData);
    const updation =
      userData.availablility != null
        ? userData.availablility.concat(payloads)
        : payloads;

    dispatch(Actions.setAvailability(updation));
    dispatch(BaseActions.showLoading(false));
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
  };

  const handleSignup = async (val) => {
    dispatch(await Actions.signupSubmit(val));
  };

  const handleNavigate = (path, firstname, lastname, email) => {
    console.log(firstname, lastname, email, path);

    const data = [firstname, lastname, email];
    console.log("Navigating correctly");
    console.log(`${path}?from=signup`);
    props.history.push(`${path}?from=signup`, data);
  };

  var resfname = "";
  var reslname = "";
  var resemail = "";
  if (props.location.state) {
    resfname = props.location.state[0];
    reslname = props.location.state[1];
    resemail = props.location.state[2];
  }

  // window.history.replaceState(null, '')

  const onFocus = () => {
    setHideButton(true);
  };

  const handleBlur = () => {
    setHideButton(false);
  };

  return (
    <Formik
      initialValues={{
        // first_name: "",
        first_name: resfname,
        // last_name: "",
        last_name: reslname,
        // email: "",
        email: resemail,
        password: "",
        confirm_password: "",
        day: "15",
        month: "",
        year: "2000",
        // fullPhoneNumber: Math.random()
      }}
      onSubmit={async (values) => {
        const { day, month, year, confirm_password, password, ...rest } =
          values;
        let modifiedValue = {
          ...rest,
          password: password,
          time_zone: new Date().toString().match(/([-\+][0-9]+)\s/)[1],
          dob: `${month}-${day}-${year}`,
        };
        // console.table(modifiedValue);
        handleSignup(modifiedValue);
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string()
          .trim()
          .min(2, "*Minimum 2 characters.")
          // .test('*first_name_alphabets', '*Name must start in uppercase.', (value) => /^[A-Z]+$/.test(value[0]))
          .test("*alphabets", "Name can not contain numbers.", (value) => {
            return /^[A-Za-z\s]+$/.test(value);
          })
          .max(30, "*Maximum 30 characters.")
          .required("*Required"),
        last_name: Yup.string()
          .trim()
          .min(2, "*Minimum 2 characters.")
          // .test('*last_name_alphabets', '*Name must start in uppercase.', (value) => /^[A-Z]+$/.test(value[0]))
          .test("*alphabets", "Name can not contain numbers.", (value) => {
            return /^[A-Za-z\s]+$/.test(value);
          })
          .max(30, "*Maximum 30 characters.")
          .required("*Required."),
        password: Yup.string()
          .trim()
          .min(8, "*Minimum 8 characters.")
          .test(
            "*Password",
            "*Must contain Uppercase, Lowercase  and Number.",
            // "*Must contain Uppercase or Lowercase or Number or Special chars",
            (value) => {
              return passwordRegex.test(value);
            }
          )
          .max(15, "*Should be or less than 15 characters.")
          .required("*Required."),
        confirm_password: Yup.string()
          .trim()
          .min(8, "*Minimum 8 characters.")
          .matches(
            passwordRegex,
            "*Must contain Uppercase, Lowercase, and Number."
            // "*Must contain Uppercase or Lowercase or Number or Special chars",
          )
          .max(15, "*Should be or less than 15 characters.")
          .oneOf(
            [Yup.ref("password"), null],
            "*Passwords did not match. Please try again."
          )
          .required("*Required"),
        email: Yup.string()
          .email("*Invalid email address.")
          .required("*Email Required."),
        month: Yup.string()
          .max(2, "*length should be 2.")
          .required("*Month Required."),
        day: Yup.string()
          .trim()
          .test(
            "*day",
            "*Date should be in between 1 - 31",
            (value) => Number(value) <= 31 && Number(value) > 0
          ),
        // .max(2, "Date should be 0 - 31")
        // .required("*Day Required"),
        year: Yup.string()
          .length(4, "*Length should be 4.")
          .trim()
          .test(
            "day",
            `*You must be 18 years of age or older to use Quartrly.`,
            (value) => Number(value) <= new Date().getFullYear() - 18
          ),
        // .max(4, "Date should be 1970 - 31")
        // .required("*Year Required*"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          isValid,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
        } = props;
        // console.table(errors);
        //by Shahroze   \/ \/ \/ \/
        var dt = new Date();
        const getYear = new Date().getFullYear();
        const minOffset = 0;
        const maxOffset = 100 + (getYear % 100);
        const years = [];
        for (let i = minOffset; i <= maxOffset; i++) {
          const year = getYear - i;
          const yeartoString = year.toString();
          years.push({ label: yeartoString, value: yeartoString });
        }
        var daysInMonth = new Date(values.year, values.month, 0).getDate();
        const days = [];
        for (let i = 0; i <= daysInMonth; i++) {
          const daytoString = i.toString();
          days.push({ label: daytoString, value: daytoString });
        }
        //by Shahroze /\ /\ /\ /\
        return (
          <form className={classes.hideAll} onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box className={classes.wrapper}>
                <Box
                  margin={
                    isMobile ? "15px 10px 0px 10px" : "40px 10px 0px 10px"
                  }
                  display="flex"
                  alignItems="center"
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/images/backArrow.png"
                    alt="backArrow"
                    height="20px"
                    onClick={() => history.push("/")}
                  />
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h1">Sign Up</Typography>
                  </div>
                </Box>
                <Box margin="0 0 0 0" padding="20px">
                  <InputField
                    onBlur={handleBlur}
                    onFocus={onFocus}
                    label="First name"
                    error={values.first_name && errors.first_name}
                    isValid={
                      !errors.first_name &&
                      touched.first_name &&
                      values.first_name !== ""
                    }
                    value={values.first_name}
                    name="first_name"
                    onChange={handleChange}
                    maxLength="31"
                    textTransform="capitalize"
                  />
                  <InputField
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    label="Last name"
                    name="last_name"
                    error={values.last_name && errors.last_name}
                    isValid={
                      !errors.last_name &&
                      touched.last_name &&
                      values.last_name !== ""
                    }
                    value={values.last_name}
                    onChange={handleChange}
                    maxLength="31"
                    textTransform="capitalize"
                  />
                  <InputField
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    label="Password"
                    type="password"
                    name="password"
                    error={values.password && errors.password}
                    isValid={!errors.password && touched.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <InputField
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    label="Confirm Password"
                    type="password"
                    name="confirm_password"
                    error={values.confirm_password && errors.confirm_password}
                    isValid={
                      !errors.confirm_password && touched.confirm_password
                    }
                    value={values.confirm_password}
                    onChange={handleChange}
                  />
                  {/* <DropDown onDateChange={handleDateChange} /> */}
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2">Date of birth</Typography>
                    <div
                      style={{
                        color: "white",
                        fontSize: "10px",
                        marginTop: "4px",
                        position: "relative",
                      }}
                    >
                      {!errors.day &&
                        // touched.day &&
                        !errors.month &&
                        // touched.month &&
                        !errors.year && (
                          // touched.year &&
                          <img
                            src="assets/images/Tick.svg"
                            alt="validationTick"
                            style={{
                              height: "15px",
                              width: "15px",
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                            }}
                          />
                        )}
                    </div>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="space-between"
                  >
                    <TextField
                      onBlur={handleBlur}
                      color="secondary"
                      id="dob"
                      name="month"
                      select
                      error={
                        values.month && errors.month
                        // && touched.month
                      }
                      value={values.month}
                      style={{ width: "50%" }}
                      InputProps={{
                        classes: {
                          root: classes.root,
                          underline: classes.underline,
                          input: classes.inputBase,
                        },
                      }}
                      onChange={handleChange("month")}
                    >
                      {months.map((option) => (
                        <MenuItem
                          key={option.value}
                          className={classes.menuItem}
                          value={option.label}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      onBlur={handleBlur}
                      color="secondary"
                      id="day"
                      name="day"
                      select
                      error={
                        values.day && errors.day
                        // && touched.day
                      }
                      // value={values.day}
                      value={values.day ? values.day : "15"}
                      style={{ width: "50%" }}
                      InputProps={{
                        classes: {
                          root: classes.root,
                          underline: classes.underline,
                          input: classes.inputBase,
                        },
                      }}
                      onChange={handleChange("day")}
                    >
                      {days.map((option) => {
                        if (option.label !== "0") {
                          return (
                            <MenuItem
                              key={option.value}
                              className={classes.menuItem}
                              value={option.label}
                            >
                              {option.value}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>
                    <TextField
                      onBlur={handleBlur}
                      color="secondary"
                      id="year"
                      name="year"
                      select
                      error={
                        values.year && errors.year
                        // && touched.year
                      }
                      // value={values.year}
                      value={values.year ? values.year : "2000"}
                      style={{ width: "50%" }}
                      InputProps={{
                        classes: {
                          root: classes.root,
                          underline: classes.underline,
                          input: classes.inputBase,
                        },
                      }}
                      onChange={handleChange("year")}
                    >
                      {years.map((option) => (
                        <MenuItem
                          key={option.value}
                          className={classes.menuItem}
                          value={option.label}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <div>
                    {values.day && errors.day && (
                      // touched.day &&
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#CB3A3A", fontSize: "14px" }}
                      >
                        {errors.day}
                      </Typography>
                    )}
                  </div>
                  <div>
                    {values.year && errors.year && (
                      // touched.year &&
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#CB3A3A", fontSize: "14px" }}
                      >
                        {errors.year}
                      </Typography>
                    )}
                  </div>
                  <div style={{ height: isMobile ? "15px" : "20px" }}>
                    {values.month && errors.month && (
                      // touched.month &&
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#CB3A3A", fontSize: "14px" }}
                      >
                        {errors.month}
                      </Typography>
                    )}
                  </div>
                  <InputField
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    label="Email"
                    name="email"
                    error={
                      values.email && errors.email
                        ? // && touched.email
                          errors.email
                        : errMsg === "Email already exists"
                        ? "This email address is already registered."
                        : false
                    }
                    isValid={
                      !errors.email &&
                      touched.email &&
                      values.email !== "" &&
                      errMsg !== "Email already exists"
                    }
                    value={values.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  marginTop="-30px"
                  marginLeft="10px"
                  marginRight="40px"
                  paddingBottom="10px"
                  padding={isMobile ? "0px 7px 0px 7px" : "10px"}
                >
                  <Typography variant="subtitle2" component="span">
                    By clicking Sign Up, you are indicating that you have read
                    and acknowledge the{" "}
                    <Typography
                      className={classes.btns}
                      onClick={() =>
                        handleNavigate(
                          "/terms",
                          values.first_name,
                          values.last_name,
                          values.email
                        )
                      }
                      component="span"
                      variant="subtitle1"
                    >
                      Terms of Service
                    </Typography>{" "}
                    and{" "}
                    <Typography
                      className={classes.btns}
                      onClick={() =>
                        handleNavigate(
                          "/privacy",
                          values.first_name,
                          values.last_name,
                          values.email
                        )
                      }
                      component="span"
                      variant="subtitle1"
                    >
                      Privacy Notice
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box style={{ position: "relative" }}>
                <Box
                  padding="auto 20px 10px 20px"
                  // padding="30px"
                  paddingTop="10px"
                  paddingLeft="30px"
                  paddingRight="30px"
                  paddingBottom={isMobile ? "10px" : "32px"}
                  className={isMobile && classes.btnBottom}
                >
                  <Button
                    fullWidth
                    type="submit"
                    disabled={!dirty || !isValid || signupResponse.isLoading}
                  >
                    {signupResponse.isLoading ? <Spinner /> : "Sign Up"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
export default withReducer("Signup", reducer)(withRouter(SignupForm));

import React, { useEffect, useState } from 'react'
import Header from './Header'
import {
    // alpha,
    makeStyles
} from '@material-ui/core/styles'
import Footer from './Footer'
import InputBase from '@material-ui/core/InputBase'
import { AiOutlineSearch } from 'react-icons/ai'
// import USA from '../assets/USA.svg'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios'
import { useDispatch } from 'react-redux'
import * as BaseActions from 'store/actions'
// import { AiOutlineRight } from 'react-icons/ai';
// import { AiOutlineLeft } from 'react-icons/ai';
import NavigateLeft from '@assets/LandingPageAssets/left.svg'
import NavigateRight from '@assets/LandingPageAssets/right.svg'
import { useHistory } from 'react-router-dom'
import './App.css'
const useStyles = makeStyles((theme) => ({
    bannerHeading: {
        color: '#31A7DB',
        // fontFamily: 'Cerebri-Sans',
        fontSize: '40px',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '0px',
        [theme.breakpoints.down('md')]: {
            fontSize: '30px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            textAlign: 'center',
        }
    },
    background: {
        backgroundColor: "#080A0A"
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            // backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        margin: "5% auto",
        // width: '35%',
        background: '#161616',
        [theme.breakpoints.down('sm')]: {
            // marginLeft: theme.spacing(1),
            width: '100%',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
        color: '#808080'
    },
    inputRoot: {
        color: '#808080',
        marginLeft: '130px',
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '110px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '80px',
        }
    },
    inputRoot2: {
        color: '#808080',
        // marginLeft: '130px'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + 0}px)`,
        // transition: theme.transitions.create('width'),
        width: '350px',
        paddingTop: '5%',
        paddingBottom: '5%',
        textTransform: 'capitalize',
        marginLeft: "-25px",
        [theme.breakpoints.down('sm')]: {
            width: '227px',
        },
    },
    inputInput2: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + 0}px)`,
        // transition: theme.transitions.create('width'),
        width: '350px',
        paddingTop: '3%',
        paddingBottom: '3%',
        textTransform: 'capitalize',
        marginLeft: "164px",
        fontSize: '20px',
        textTransform: 'lowercase',
        [theme.breakpoints.down('sm')]: {
            width: '227px',
            marginLeft: "74px",
            fontSize: '18px',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    modalContainer: {
        backgroundColor: "#111111",
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "532px",
        height: '300px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: "350px",
            height: '240px',
        }
    },
    searchContainer: {
        width: '37%',
        margin: '4% auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    countryContainer: {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '0.2',
        width: "calc(300px - 10px)",
        marginTop: '5%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: "calc(30% - 10px)",
            marginTop: '10%',
            position: 'relative',
            height: '155px'
        }
    },
    voteButton: {
        border: "2px solid #fff",
        borderRadius: '5px',
        color: '#fff',
        background: 'transparent',
        width: '82px',
        height: '35px',
        cursor: "pointer",
        '&:hover': {
            color: '#111111',
            background: '#fff'
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            bottom: "-10px"
        }
    },
    submitButton: {
        border: "2px solid #fff",
        borderRadius: '5px',
        color: '#fff',
        background: 'transparent',
        width: '82px',
        height: '35px',
        cursor: "pointer",
        '&:hover': {
            color: '#111111',
            background: '#fff'
        }
    },
    circle: {
        backgroundColor: '#30A8DB',
        color: "#fff",
        width: "40px",
        height: "40px",
        lineHeight: "35px",
        borderRadius: "25px",
        textAlign: "center",
        position: "absolute",
        top: "-15px",
        right: "45px",
        fontWeight: "bold",
        fontSize: "24px",
        border: '3px solid #30A8DB',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            lineHeight: '22px',
            top: '-15px',
            right: '-4px',
            fontSize: '20px'
        },
        [theme.breakpoints.down('xs')]: {
            top: "-16px",
            right: "-13px",
            fontSize: "17px",
            width: "30px",
            height: "30px",
            lineHeight: "24px",
        }
    },
    countryImage: {
        height: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '80px',
            height: '50px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '90px',
            height: '60px'
        }
    },
    sliderButton: {
        // color: 'white',
        width: '25px',
        height: '5px',
        // borderRadius: "25px",
        border: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: '25px',
            height: '5px',
            // borderRadius: "25px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '25px',
            height: '5px',
            // borderRadius: "25px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '15px',
            height: '5px',
            // borderRadius: "25px",
        }
    },
    navigate: {
        // color: 'white',
        width: '50px',
        height: '50px',
        // borderRadius: "25px",
        border: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: '40px',
            height: '40px',
            // borderRadius: "25px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            // borderRadius: "25px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '50px',
            height: '50px',
            // borderRadius: "25px",
        }
    },
    navigateIcon: {
        color: 'white',
        fontSize: '40px'
    },
    countryName: {
        color: '#808080',
        textAlign: 'center',
        fontSize: '24px',
        // whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '1',
        }
    },
    countryImageHeadingContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: '5% 0%'
        }
    },
    countryImageHeading: {
        color: '#808080',
        textAlign: 'center',
        fontSize: '24px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '18px',
            whiteSpace: 'normal',
        }
    },
    flagsContainer: {
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    navigationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '33%',
        padding: '5% 0%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    mobileSearch: {
        width: '35%',
        margin: '5% auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    nav: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginTop: "10%"
        }
    },
    buttonContainer: {
        padding: '2% 2%',
        marginLeft: '14%',
        position: 'absolute',
        left: '-1%',
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            left: '1%',
        }
    },
    searchBaricon: {
        fontSize: '25px',
        lineHeight: '10px'
    }
}))
const Vote = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [countries, setCountries] = useState([]);
    const [countryName, setCountryName] = useState('');
    const [voteCountry, setVoteCountry] = useState('');
    const [email, setEmail] = useState('');
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(25);
    const [invalidEmail, setInvalidEmail] = useState(true)
    // const [dummyArray, setDummyArray] = useState([]);
    // const [totalCountries, setTotalCountries] = useState(0)
    const [hideButtons, setHideButtons] = useState(false)
    // const [search, setSearch] = useState("");
    const [searched, setSearched] = useState(false);
    // const history = useHistory();
    const handleOpen = (e) => {
        setOpen(true);
        // console.log(e)
        setVoteCountry(e)
    };
    const handleClose = () => {
        setOpen(false);
    };
    // const data = [];
    useEffect(() => {
        setHideButtons(true)
        axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}flag/get-all`)
            .then(res => {
                setCountries(res.data.data)
                setTimeout(() => {
                    dispatch(BaseActions.hideLoading());
                    setHideButtons(false)
                }, 8000);
            })
    }, [])
    useEffect(() => {
    }, [countryName])
    const handleChange = (e) => {
        const value = e.target.value.toLowerCase()
        setCountryName(value)
        setSearched(true)
    }
    const handleEmail = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(e.target.value)) {
            setInvalidEmail(false)
            setEmail(e.target.value)
        }
        else {
            setInvalidEmail(true)
            return;
        }
    }
    // let timeoutRef = null
    // const searchTextUpdate = (e) => {
    //     if (timeoutRef) {
    //         clearTimeout(timeoutRef)
    //         timeoutRef = null
    //     } else {
    //         if (e.target.value === null || e.target.value === undefined) {
    //             setSearch("")
    //         }
    //         else {
    //             if (e.target.value === "") {
    //                 setHideButtons(false)
    //             }
    //             else {
    //                 setSearch(e.target.value)
    //                 setHideButtons(true)
    //                 timeoutRef = setTimeout(() => handleChange(search), 500);
    //             }
    //         }
    //     }
    // }
    const handleSubmit = () => {
        const data = {
            country: voteCountry,
            email: email
        }
        axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}flag/add-vote`, data)
        setOpen(false)
        window.location.reload(false);
    }
    // console.log("start", start)
    // console.log("end", end)
    useEffect(() => {
        // setTotalCountries(countries.length)
        if (end > 197) {
            dispatch(BaseActions.hideLoading());
            setEnd(197)
            setStart(182)
        }
        if (start < 0){
            dispatch(BaseActions.hideLoading());
            setStart(0)
            setEnd(25)
        }
    }, [countryName, countries, start, end])
    const throttle = () => {
        dispatch(BaseActions.showLoading(true))
        setTimeout(() => {
            dispatch(BaseActions.hideLoading());
        }, 2000);
    }
    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({ fullScreen: true }));
    }, [])
    return (
        <div className={classes.background}>
            <Header />
            <div style={{ margin: '0' }} className="font-face-bold">
                <h1 className={classes.bannerHeading}>Vote to make Quartrly available<br /> in YOUR country!</h1>
            </div>
            <div className={classes.mobileSearch}>
                <div className={classes.searchContainer} className="font-face-regular">
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <span className={classes.searchBaricon}><AiOutlineSearch /></span>
                        </div>
                        <InputBase
                            placeholder="Search for your country"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            // inputProps={{ 'aria-label': 'search' }}
                            onChange={handleChange}
                            className="font-face-regular"
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.modalContainer}>
                            <div style={{ position: 'absolute', right: '10px', top: '10px', fontSize: '25px' }}>
                                <span style={{ color: '#CB3A3A', cursor: 'pointer' }} onClick={handleClose}><AiOutlineClose /></span>
                            </div>
                            <h2 className="font-face-bold" style={{ color: '#31A7DB', textAlign: 'center', padding: '5% 0%' }}>Submit your vote</h2>
                            <div style={{ width: '100%', margin: '4% auto' }}>
                                <div className={classes.search}>
                                    <InputBase
                                        placeholder="Enter your email"
                                        classes={{
                                            root: classes.inputRoot2,
                                            input: classes.inputInput2,
                                        }}
                                        onChange={handleEmail}
                                        // inputProps={{ 'aria-label': 'search' }}
                                        className="font-face-regular"
                                        type="email"
                                    />
                                </div>
                                <div className="font-face-regular" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%', padding: '5% 0%' }}>
                                    <button type="button" className={classes.submitButton} onClick={() => handleSubmit()} disabled={invalidEmail}>Submit</button>
                                    {invalidEmail && <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', position: 'absolute', fontSize: '14px', bottom: '77px', display: !invalidEmail && email.trim().length === 0 ? "none" : null }}>*Enter a valid email address</span>}
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <div class={classes.flagsContainer}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        {/* {countries.map((country) => (
                            <div className={classes.countryContainer}>
                                <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                    <div className={classes.circle}>{country.votes.length}</div>
                                    <img src={`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/flags/${country.flagImage}`} className={classes.countryImage} />
                                </div>
                                <div style={{}}>
                                    <h1 style={{ color: '#808080', textAlign: 'center', fontSize: '24px', whiteSpace: 'nowrap' }}>{country.country}</h1>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                    <button type="button" onClick={() => handleOpen(country.country)} className={classes.voteButton}>Vote</button>
                                </div>
                            </div>
                        ))} */}
                        {countries.filter((country) => country.country.toLowerCase().includes(countryName)).map((filteredName, i) => {
                            if (searched) {
                                return <div className={classes.countryContainer}>
                                    <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                        <div className={classes.circle}>{filteredName.votes.length}</div>
                                        <img src={`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/flags/${filteredName.flagImage}`} className={classes.countryImage} />
                                    </div>
                                    <div className={classes.countryImageHeadingContainer}>
                                        <h1 className={classes.countryImageHeading}><span className="font-face-regular">{filteredName.country}</span></h1>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16%' }}>
                                        <button type="button" onClick={() => handleOpen(filteredName.country)} className={classes.voteButton}>Vote</button>
                                    </div>
                                </div>
                            } else if (i >= start && i <= end) {
                                return <div className={classes.countryContainer}>
                                    <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                        <div className={classes.circle}>{filteredName.votes.length}</div>
                                        <img src={`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/flags/${filteredName.flagImage}`} className={classes.countryImage} />
                                    </div>
                                    <div className={classes.countryImageHeadingContainer}>
                                        <h1 className={classes.countryImageHeading}><span className="font-face-regular">{filteredName.country}</span></h1>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16%' }}>
                                        <button type="button" onClick={() => handleOpen(filteredName.country)} className={classes.voteButton}>Vote</button>
                                    </div>
                                </div>
                            }
                        }
                        )}
                    </div>
                    {
                        searched ? null :
                            <div className={classes.nav}>
                                <div style={{ display: hideButtons ? "none" : "" }} className={classes.navigationContainer}>
                                    <div className={classes.navigate} style={{ backgroundImage: `url(${NavigateLeft})`, position: 'absolute', left: 0}} onClick={() => { setStart(start - 26); setEnd(end - 26); throttle() }}></div>
                                    <div className={classes.buttonContainer}>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 0 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(0); setEnd(25); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 26 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(26); setEnd(51); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 52 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(52); setEnd(77); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 78 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(78); setEnd(103); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 104 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(104); setEnd(129); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 130 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(130); setEnd(155); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 156 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(156); setEnd(181); throttle() }}></button></div>
                                        <div style={{ padding: '0% 2%', float: 'left' }}><button className={classes.sliderButton} style={{ background: start === 182 ? '#30A8DB' : '#5C5D5D' }} onClick={() => { setStart(182); setEnd(197); throttle() }}></button></div>
                                    </div>
                                    <div className={classes.navigate} style={{ backgroundImage: `url(${NavigateRight})`, position: 'absolute', right: 0 }} onClick={() => { setStart(start + 26); setEnd(end + 26); throttle() }}></div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Vote

import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box, Button, Paper, Typography,
	// Backdrop 
} from "@material-ui/core";
import Pics from "../../@assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import BlockIcon from "@material-ui/icons/Block";
// import ReportIcon from "@material-ui/icons/Report";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as BaseActions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import StarRateIcon from "@material-ui/icons/StarRate";
import Reviews from "components/reviews";
import BlockUser from "components/blockUser/BlockUser";
import ReportUser from "components/blockUser/ReportUser";
import Carousel from "react-multi-carousel";
// import { LaptopWindowsRounded } from '@material-ui/icons';
import { useParams } from "react-router"
import { isAndroid, isMobile } from "react-device-detect";
import DummyImage from "../../@assets/dummyImage.png"
const responsive = {
	all: {
		breakpoint: { max: 4000, min: 0 },
		items: 1,
	},
};
const useStyles = makeStyles((theme) => ({
	container: {
		height: "180px",
		objectFit: 'scale-down',
		position: "relative",
		borderRadius: 6
	},
	video: {
		backgroundColor: "black",
		position: "absolute",
		height: "180px",
		width: "100%",
	},
	backButton: {
		position: "absolute",
		top: 10,
		left: 20,
		backgroundColor: "rgba(0,0,0,0.4)",
		borderRadius: 6,
		paddingLeft: 6,
		color: "white",
		cursor: "pointer",
		height: '30px',
		width: '30px'
	},
	editButton: {
		position: "absolute",
		top: 10,
		right: 20,
		backgroundColor: "rgba(0,0,0,0.4)",
		borderRadius: 6,
		paddingLeft: 6,
		color: "white",
		cursor: "pointer",
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
	},
	menuButton: {
		zIndex: 11,
	},
	main: {
		flex: 1,
		height: height,
		overflow: "scroll",
		padding: 20,
		[theme.breakpoints.up("sm")]: {
			paddingBottom: 70,
		},
		[theme.breakpoints.down("sm")]: {
			padding: "0px 20px",
		},
	},
	title: {
		color: "white",
		fontSize: 24,
		textTransform: "capitalize",
	},
	price: {
		fontSize: 24,
	},
	tags: {
		fontSize: 12,
		backgroundColor: "#09161C",
		fontFamily: "cerebri-regular",
		borderRadius: 6,
		padding: "5px 10px",
		margin: "0 10px 10px 0",
	},
	slider: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
		bottom: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	line: {
		marginTop: -25,
		marginRight: 10,
		height: 10,
		width: 20,
		cursor: "pointer",
	},
	menuContainer: {
		color: "white",
		position: "absolute",
		right: "-27px",
		top: "-2px",
		backgroundColor: "rgba(0,0,0,0.4)",
		padding: "10px 0",
		minWidth: "150px",
		"&:before": {
			marginTop: "-20px",
		},
	},
	menuItem: {
		padding: "5px 10px",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "grey",
		},
	},
	buttonBottom: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			bottom: 10,
		},
		position: "fixed",
		bottom: 40,
		width: '330px'
	},
	btnContainer: {
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
		},
		bottom: 10,
		position: "fixed",
		marginBottom: "-10px",
		backgroundColor: "#080A0A",
		paddingBottom: "50px",
		width: "100%",
		height: "70px"
	}
}));
function viewportToPixels(value) {
	var parts = value.match(/([0-9\.]+)(vh|vw)/)
	var q = Number(parts[1])
	var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
	return side * (q / 100)
}
const height = viewportToPixels("100vh") - 450
const CustomDot = ({ active, onClick, carouselState, set, profile_media_urls }) => {
	const classes = useStyles();
	if (profile_media_urls) {
		carouselState.totalItems = profile_media_urls.length + 1;
	}
	carouselState.currentSlide === 3 ? set(true) : set(false);
	return (
		<img
			alt="arrow"
			src={active ? Pics.blueLine : Pics.whiteLine}
			className={classes.line}
			onClick={() => onClick()}
		/>
	);
};
function UserProfile({ history, match, ...props }) {
	const { id } = useParams();
	const UserProfileID = id;
	const dispatch = useDispatch();
	const classes = useStyles();
	const { userData, layout } = useSelector(({ Profile, BaseReducer }) => ({
		userData: Profile.FetchProfileReducer,
		layout: BaseReducer.LayoutReducer,
	}));
	// console.log(userData)
	const ratesResponse = useSelector(({ Profile }) => Profile.GetRatesReducer);
	const {
		companyFee,
		referralFee,
		transactionFee
	} = ratesResponse.data ? ratesResponse.data : {}
	const [refresh, setRefresh] = useState(false);
	const [play, setPlay] = useState(0);
	const [user, setUserData] = React.useState({});
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: false }));
		dispatch(BaseActions.getUserData());
		return () => dispatch(Actions.resetProfile())
	}, [dispatch]);
	React.useEffect(() => {
		if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [layout.bottomNav])
	useDeepCompareEffect(() => {
		if (userData.data && userData.data.status === "Success") {
			setUserData(userData.data.data);
			let charges = 0
			if (userData.data.data.referrer_is_set === 0) {
				charges = companyFee + transactionFee + userData.data.data.rate
				// console.log({ chargesif: charges })
				// console.log({ companyFee, transactionFee, rate: userData.data.data.rate })
			} else {
				charges = companyFee + referralFee + transactionFee + userData.data.data.rate
				// console.log({ chargeselse: charges })
			}
			if (refresh) {
				history.push({
					pathname: "/booking",
					state: {
						_id: userData.data.data._id,
						rate: charges,
						full_name: userData.data.data.full_name,
						userImage: userData.data.data.profile_media_urls[0],
						availability: userData.data.data.availablility,
						charges: userData.data.data.rate,
						enabledUsername: userData.data.data.enabledUsername,
						username: userData.data.data.username
					},
				});
			}
			return;
		}
	}, [userData]);
	const {
		tags,
		profile_media_urls,
		full_name,
		enabledUsername,
		username,
		rate,
		// user_number_in_users,
		rating,
		bio,
		reviews_count,
		video_url,
		user_code,
		_id,
		// availablility,
	} = user;
	const [shouldUseImage, setShouldUseImage] = useState(false);
	const videoRef = React.useRef(null)
	React.useEffect(() => {
		if (isSafari() && videoRef.current) {
			// obtain reference to the video element
			const player = videoRef.current.children[0];
			// if the reference to video player has been obtained
			if (player) {
				// set the video attributes using javascript as per the
				// webkit Policy
				player.controls = false;
				player.playsinline = true;
				// player.muted = true;
				// player.setAttribute("muted", ""); // leave no stones unturned :)
				player.autoplay = false;
				// Let's wait for an event loop tick and be async.
				setTimeout(() => {
					// player.play() might return a promise but it's not guaranteed crossbrowser.
					const promise = player.play();
					// let's play safe to ensure that if we do have a promise
					if (promise.then) {
						promise
							.then(() => { })
							.catch(() => {
								// if promise fails, hide the video and fallback to <img> tag
								videoRef.current.style.display = "none";
								setShouldUseImage(true);
							});
					}
				}, 0);
			}
		}
	}, [])
	const handleStopVideo = (currentSlide) => {
		if (videoRef.current?.children[0]) {
			videoRef.current.children[0].pause()
		}
	}
	const isSafari = () => {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
	};
	React.useEffect(() => {
		if (window.location.pathname.split('/').pop().length > 10) {
			dispatch(Actions.fetchProfile(window.location.pathname.split('/').pop()));
		} else {
			dispatch(Actions.fetchProfileByRefferal(window.location.pathname.split('/').pop()));
		}
	}, [dispatch, refresh]);
	const handleReviews = (id, count) => {
		dispatch(
			BaseActions.showMessage(<Reviews handleClose={handleClose} count={count} userId={id} />)
		);
	};
	const handleClose = () => {
		dispatch(BaseActions.hideMessage());
	};
	const blockUser = (id) => {
		setOpen(false);
		dispatch(
			BaseActions.showMessage(<BlockUser userId={id} userName={full_name} />)
		);
	};
	const reportUser = (id) => {
		setOpen(false);
		dispatch(
			BaseActions.showMessage(<ReportUser userId={id} userName={full_name} />)
		);
	};
	const handleGoBack = () => {
		const tag = history.location.state?.tag
		if (window.location.search.includes('from=search')) {
			history.push({ pathname: `/search/${history.location.state.tab}`, state: { searchValue: history.location.state.searchValue } })
		} else if (window.location.search.includes('from=expertise')) {
			history.push({ pathname: `/profilesintag`, state: tag })
		} else if (window.location.search.includes('from=notifications')) {
			history.push('/notifications')
		}
		else {
			history.push('/home')
		}
	}
	const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
	const loggedInUserId = isLoggedIn?.data?._id;
	// console.log("loggedInUserId", loggedInUserId)
	const token = localStorage.getItem("JWTtoken");
	const handleClick = () => {
		if (isLoggedIn && token) {
			setRefresh(true)
		} else {
			history.push(`/signup?id=${_id}&code=${user_code}`)
		}
	}
	return (
		<Box
			position="relative"
			height="100%"
			width="100%"
			overflow={"hidden"}
			padding='40px 0 0 0'
		>
			{
				(isLoggedIn && token) &&
				<Box>
					<ArrowBackIosIcon
						className={classes.backButton}
						onClick={handleGoBack}
					/>
					<MoreVertIcon
						className={clsx(classes.editButton, classes.menuButton)}
						style={{ padding: 5, height: '30px', width: '30px' }}
						onClick={() => setOpen(!open)}
					/>
				</Box>
			}
			{open && (
				<Box
					position="fixed"
					height="100%"
					width="100%"
					zIndex="10"
					style={{
						backgroundColor: "#000000a1",
					}}
				>
					<div className={classes.menuContainer}>
						<div className={classes.menuItem} onClick={() => blockUser(_id)}>
							<img
								alt="icon"
								src={Pics.blockIcon}
								style={{ marginRight: "10px" }}
								width={20}
							/>
							<Typography style={{ fontSize: "16px" }} variant="body1">
								Block
							</Typography>
						</div>
						<div className={classes.menuItem} onClick={() => reportUser(_id)}>
							<img
								alt="icon"
								src={Pics.reportIcon}
								style={{ marginRight: "10px" }}
								width={20}
							/>
							<Typography style={{ fontSize: "16px" }} variant="body1">
								Report
							</Typography>
						</div>
					</div>
				</Box>
			)}
			<Box style={{ position: "relative" }} display="flex" flexDirection="column" height="100%">
				<div style={{
					backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
					height: "20px",
					width: "100%",
					position: "absolute", bottom: 0
				}}></div>
				<Paper style={{ backgroundColor: "#080A0A", flexGrow: 1, overflow: 'auto' }}>
					<div style={{ position: 'relative', }}>
						<Carousel
							draggable
							responsive={responsive}
							keyBoardControl={true}
							showDots={true}
							// dotListClass={"dots-list-custom"}
							removeArrowOnDeviceType={["all"]}
							customDot={<CustomDot set={setPlay} profile_media_urls={profile_media_urls} />}
						>
							{profile_media_urls?.map((pic, idx) => (
								pic ? <img
									key={idx}
									className={classes.container}
									alt="img"
									// src={pic + `?${new Date().getTime()}`}
									src={`${pic === "https://narsunprojects.com:3001/uploads/default_pic.png" ||
										pic === "" ||
										pic === "https://api.quartrly.com/uploads/default_pic.png" ? DummyImage : pic}`}
									draggable={false}
									width="100%"
								/>
									:
									<img
										key={idx}
										className={classes.container}
										alt="img"
										// src={pic + `?${new Date().getTime()}`}
										src={DummyImage}
										draggable={false}
										width="100%"
									/>
							))}
							{video_url !== "" ? (
								<div className={classes.video}>
									{
										shouldUseImage ? (
											<img src={video_url} alt="Muted Video" />
										) : (
											<div
												ref={videoRef}
												dangerouslySetInnerHTML={{
													__html: `
											<video
											  loop
											  controls="false"
											  style="margin-top: -40px"
											  height="220px"
											  width="100%"
											  playsinline
											  preload="metadata"
											>
											<source src="${video_url}" type="video/mp4" />
											</video>`
												}}
											/>
										)
									}
								</div>
							) : (
								<div
									style={{
										width: "100%",
										height: 200,
										display: "flex",
										backgroundColor: "#E0E0E0",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography
										style={{
											color: "#c7c7c7",
											fontSize: 30,
										}}
									>
										No video uploaded
									</Typography>
								</div>
							)}
						</Carousel>

					</div>
					<Box className={classes.main}>
						<Box className={classes.inline}>
							<Typography variant="h1" className={classes.title}>
								{enabledUsername ? username : full_name}
							</Typography>
							<Typography variant="body2" className={classes.price}>
								{/* {rate <= 0 ? "Free" : rate && `$${Number(rate).toFixed(2)}`} */}
								{rate <= 0 ? "Free" : rate && `$${Number(rate)}`}
							</Typography>
						</Box>
						<Box
							display="flex"
							flexDirection="row"
							margin={isMobile ? "10px 0" : "20px 0"}
							onClick={() => handleReviews(_id, reviews_count)}
							style={{ cursor: "pointer" }}
						>
							<StarRateIcon style={{ color: "#FCBD3F" }} />
							<Typography variant="body1">

							{/* {user.rating == 0 ? 'No Reviews' :  user.rating.toFixed(1)} */}
							{reviews_count  == 0 ? "No Reviews" :	`${rating?.toFixed(1)} (${reviews_count} reviews) `}
							</Typography>
						</Box>
						<Typography variant="caption">
							{bio ? bio : "No bio information."}
						</Typography>
						<Box>
							{tags?.length > 0 && (
								<>
									<Box className={classes.inline} margin={isMobile ? "10px 0" : "20px 0"}>
										<Typography variant="body2">Expertise</Typography>
										<Typography variant="body2">Years</Typography>
									</Box>
									{tags
										?.sort(function (a, b) {
											return b.years - a.years;
										})
										.map((t, i) => (
											<Box key={i} className={classes.inline} margin="10px 0">
												<Typography variant="caption">{t.expertise}</Typography>
												<Typography
													variant="caption"
													style={{ marginRight: 14 }}
												>
													{t.years}
												</Typography>
											</Box>
										))}
								</>
							)}
						</Box>
					</Box>
				</Paper>
			</Box>
			<Box className={classes.btnContainer} display="flex" justifyContent="center">
				{UserProfileID !== loggedInUserId ? (
					<Button className={classes.buttonBottom} onClick={handleClick} fullWidth>Book a Quartr</Button>
				) : null}
			</Box>
		</Box >
	);
}
export default withReducer("Profile", reducer)(withRouter(UserProfile));

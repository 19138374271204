import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Detector } from "react-detect-offline";
import { useSelector } from "react-redux";
import noConnectionBackImage from '../../@assets/no-connection.png'
import noConnectionIconImage from '../../@assets/no-connection-icon.png'
import { isMobile } from "react-device-detect";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  connectionBack: {
    backgroundImage: `url(${noConnectionBackImage})`,
    width: isMobile ? "85%" : "42%",
    height: '19vh',
    position: "relative",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    padding: "2% 0%"
  },
  connectionText: {
    fontFamily: "cerebri-regular",
    fontSize: '18px',
    fontWeight: 'bold',
    padding: "3% 0%",
    color: 'grey'
  },
  icon: {
    color: 'rgb(203, 58, 58)',
    width: "90%",
    display: "flex",
    justifyContent: "flex-end",
    cursor: 'pointer'
  }
}));
export default function BackDrop({ open }) {
  const classes = useStyles();
  const [overlay, setOverlay] = useState(null) //for hiding overlay
  const handleClose = () => {
    //it will reset overlay, when we close popup, and turn on off again internet, the overlay will show again
    //and will display spinner in the center of the overly, when we are online again
    setOverlay(null)
    return (
      <CircularProgress color="inherit" />
    )
  }
  const isLoading = useSelector(
    ({ BaseReducer }) => BaseReducer.LoadingReducer.state
  );
  //   const [open, setOpen] = React.useState(false);
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  return (
    <div>
      {/** Internet disconnction */}
      {/* <Detector
        render={({ online }) => (
          <Backdrop className={classes.backdrop} style={{ zIndex: online ? 1021 : 10200000, backgroundColor: online ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.8)' }} open={online || overlay ? isLoading : true}>
            {online && handleClose()}
            {!online && <div className={classes.connectionBack}>
              <span className={classes.icon} onClick={() => setOverlay(true)}><CloseIcon /></span>
              <img src={noConnectionIconImage} style={{ width: '14%' }} />
              <span className={classes.connectionText}>No Internet Connection</span>
            </div>}
          </Backdrop>
        )}
      /> */}

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

import { makeStyles, Switch } from "@material-ui/core";
import React from "react";

import { useDispatch } from "react-redux";
import * as BaseActions from "store/actions";
import AvailabilityDrawer from "components/availabilityDrawer";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 56,
    height: 34,
    padding: 0,
    margin: theme.spacing(1),
    marginRight: 0,
  },
  switchBase: {
    padding: 5,
    color: "#CB3A3A",
    "&$checked": {
      transform: "translateX(22px)",
      color: "#4CDE5F",
      "& + $track": {
        backgroundColor: "#193010",
        opacity: 1,
        border: "none",
      },
    },
  },
  switchBaseBlue: {
    padding: 5,
    color: "Black",
    "&$checked": {
      transform: "translateX(22px)",
      color: "#4CDE5F",
      "& + $track": {
        backgroundColor: "#193010",
        opacity: 1,
        border: "none",
      },
    },
  },
  switchBaseDark: {
    padding: 5,
    "&$checked": {
      transform: "translateX(22px)",
      color: "#4CDE5F",
      "& + $trackDark": {
        backgroundColor: "#193010",
        opacity: 1,
        border: "none",
      },
      "& > .thumbDark": {
        color: "#4CDE5F !important",
      },
    },
    "&$focusVisible $thumbDark": {
      color: "#4CDE5F",
    },
  },
  switchBaseDarkBlue: {
    padding: 5,
    "&$checked": {
      transform: "translateX(22px)",
      color: "#Yellow",
      "& + $trackDark": {
        backgroundColor: "#193010",
        opacity: 1,
        border: "none",
      },
      "& > .thumbDark": {
        color: "#Yellow !important",
      },
    },
    "&$focusVisible $thumbDark": {
      color: "#Yellow",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    // color:'#fff'
  },
  thumbDark: {
    width: 24,
    height: 24,
    // color: "#fff",
  },
  track: {
    borderRadius: 34 / 2,
    backgroundColor: "#301010",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  trackDark: {
    borderRadius: 34 / 2,
    backgroundColor: "#CB3A3A",
    color: "#4CDE5F",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

function MySwitch({ isDark,checked,handleChange, ...props }) {
  const classes = useStyles();


  return (
    <Switch

    disabled={props.isLoading}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={checked}
      onChange={() => {
        handleChange();
      }}
      classes={{
        root: classes.root,
        switchBase: isDark ? classes.switchBaseDark : classes.switchBase,
        thumb: isDark ? classes.thumbDark : classes.thumb,
        track: isDark ? classes.trackDark : classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}

export default React.memo(MySwitch);

import React, { useCallback, useRef, useState, useEffect } from "react";
import clsx from "clsx";
// import Header from "components/home/subComponents/header";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Button,
	Paper,
	Typography,
	// Radio,
	RadioGroup,
	FormControlLabel,
	// withStyles,
	Modal
} from "@material-ui/core";
import { Radio as RadioButton } from "@material-ui/core";
import Pics from "@assets";
import {
	// InputField,
	MySwitch
} from "@base";
import Expertise from "components/signup/screens/expertise";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import { uploadFile } from "react-s3";
import ImageCard from "./subComponent";
import withReducer from "store/withReducer";
import * as Actions from "../signup/screens/signupForm/store/actions";
import reducer from "../signup/screens/signupForm/store/reducers";
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider';
import {
	// ChevronLeft, ChevronRight, Rotate90DegreesCcw, 
	RotateLeft, RotateRight
} from "@material-ui/icons";
import getCroppedImg from './cropImage'
// import { isInteger } from "formik";
import DummyImage from "../../@assets/dummyImage.png"
import dummy from "../../@assets/dummyImage1.svg"
import imageCompression from 'browser-image-compression';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReactPlayer from 'react-player'
import "./index.css";
import { isIOS, isMobile, isAndroid } from "react-device-detect";
const config = {
	bucketName: process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME,
	dirName: "uploads",
	region: "us-east-2",
	accessKeyId: process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_ACCESS_KEY : process.env.REACT_APP_TESTING_S_THREE_ACCESS_KEY,
	secretAccessKey: process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_SECRET_KEY : process.env.REACT_APP_TESTING_S_THREE_SECRET_KEY,
};
const useStyles = makeStyles((theme) => ({
	container: {
		height: "375px",
		backgroundSize: "cover",
		backgroundColor: "#111111",
		position: "relative"
	},
	cardContainer: {
		height: "375px",
		backgroundColor: "rgba(0,0,0,0.2)",
		position: "relative",
		padding: "30px 20px",
		[theme.breakpoints.down("sm")]: {
		}
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
	},
	backButton: {
		// backgroundColor: "rgba(0,0,0,0.57)",
		width: "30px",
		height: "30px",
		borderRadius: 6,
		paddingLeft: 6,
		color: "white",
		cursor: "pointer",
		zIndex: 10
	},
	editButton: {
		cursor: "pointer",
		zIndex: 10
	},
	changeButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 20,
		left: 20,
		backgroundColor: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		width: "90%",
		height: 36,
		textAlign: "center",
		paddingTop: 6,
		fontSize: 16,
		zIndex: "50"
	},
	otherChangeButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 20,
		left: 20,
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		width: "90%",
		height: 36,
		textAlign: "center",
		paddingTop: 6,
		fontSize: 16,
		zIndex: "50"
	},
	body: {
		padding: 25,
	},
	main: {
		display: "flex",
		flexDirection: "row",
	},
	pic: {
		marginRight: 25,
		borderRadius: 6,
		height: 87,
		width: 87,
		backgroundSize: "cover",
		backgroundColor: "#111111",
	},
	picContainer: {
		height: 87,
		width: 87,
		backgroundColor: "rgba(0,0,0,0.2)",
		position: "relative",
		padding: "40px 20px",
	},
	changePicButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 5,
		left: 5,
		backgroundColor: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		width: "90%",
		textAlign: "center",
		fontSize: 11,
		padding: 5,
	},
	otherChangePicButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 5,
		left: 5,
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		width: "90%",
		textAlign: "center",
		fontSize: 11,
		padding: 5,
	},
	title: {
		margin: "15px 0",
	},
	expertiseButton: {
		color: "white",
		cursor: "pointer",
	},
	expertiseContainer: {
		backgroundColor: "#111111",
		borderRadius: 6,
		padding: "0 0 13px 16px",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	textAreaContainer: {
		backgroundColor: "#111111",
		borderRadius: 6,
		padding: "15px",
	},
	tags: {
		fontSize: 12,
		margin: "13px 16px 0 0",
	},
	transparentInput: {
		backgroundColor: "transparent",
		border: "0px",
		outline: "none",
		boxShadow: "none",
		width: "100%",
		color: theme.palette.secondary.main,
	},
	rateInput: {
		fontFamily: "cerebri-semibold",
		fontSize: "24px",
	},
	bioInput: {
		fontFamily: "cerebri-regular",
		fontSize: "16px",
		height: "100%",
		resize: "none",
	},
	checkedIcon: {
		"&:before": {
			borderRadius: "50%",
			display: "block",
			width: 22,
			height: 22,
			backgroundImage: "radial-gradient(#31A7DB 48%,transparent 32%)",
			border: "2px solid #FFFFFF",
			content: '""',
		}
	},
	cropContainer: {
		position: "absolute",
		top: 30,
		zIndex: "100",
		width: "370px",
		height: "300px"
	},
	imgOuterContainer: {
		[theme.breakpoints.up("sm")]: {
			width: "370px",
			height: `${height}px`,
		},
		position: "fixed",
		width: "100%",
		height: `100vh`,
		backgroundColor: "#080A0A",
		zIndex: "100",
		backgroundColor: "#080A0A",
	},
	root: {
		color: (props) => (props.isAvailable ? "#fff" : "#3b3b3b"),
		"&$checked": {
			color: "#31A7DB",
		},
	},
	rotateBtn: {
		border: "1px solid silver",
		borderRadius: 6,
		padding: "5px 5px 0 5px",
		cursor: "pointer"
	},
	btnContainer: {
		width: "370px",
		padding: "0 25px 10px 25px",
		position: "fixed",
		[theme.breakpoints.up("sm")]: {
			bottom: 40,
		},
		marginBottom: "30px",
	},
	disabled: {
		color: "#3b3b3b",
	},
	checked: {},
	checkedIcon: {
		borderRadius: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "3px",
		border: "2px solid #FFFFFF",
	},
	blueDot: {
		borderRadius: "100%",
		display: "block",
		width: 14,
		height: 14,
		backgroundColor: "#31A7DB",
		content: '""',
	},
	gradient: {
		height: 115,
		backgroundImage: "linear-gradient(#0A0A0A,#00000000 )",
		opacity: 1,
		zIndex: 9,
		position: "fixed",
		borderRadius: 6,
		width: "100%",
		top: 0,
		[theme.breakpoints.up("sm")]: {
			width: 370,
			bottom: 42,
		},
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "none",
	},
	modalContainer: {
		outline: "none",
		position: "fixed",
		bottom: 0,
		marginBottom: "0",
		[theme.breakpoints.up("sm")]: {
			marginBottom: "33px",
		},
		[theme.breakpoints.down("sm")]: {
			width: "415px",
		},
		// width: "370px",
		width: "100%",
		// padding: "10px",
		borderRadius: 0,
		color: "#FFFFFF",
		backgroundColor: "#111111",
		display: "flex",
		justifyContent: "center",
		// alignItems: "center",
	},
	modalActionContainer: {
		width: "100%",
		marginTop: "20px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
function viewportToPixels(value) {
	var parts = value.match(/([0-9\.]+)(vh|vw)/)
	var q = Number(parts[1])
	var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
	return side * (q / 100)
}
const height = viewportToPixels("100vh") - 80
// const PrettoSlider = withStyles({
// 	root: {
// 		color: '#52af77',
// 		height: 8,
// 	},
// 	thumb: {
// 		height: 24,
// 		width: 24,
// 		backgroundColor: '#fff',
// 		border: '2px solid currentColor',
// 		marginTop: -8,
// 		marginLeft: -12,
// 		'&:focus, &:hover, &$active': {
// 			boxShadow: 'inherit',
// 		},
// 	},
// 	active: {},
// 	valueLabel: {
// 		left: 'calc(-50% + 4px)',
// 	},
// 	track: {
// 		height: 8,
// 		borderRadius: 4,
// 	},
// 	rail: {
// 		height: 8,
// 		borderRadius: 4,
// 	},
// })(Slider);
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function EditProfile({ history }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const isFirstRenderRef = useRef(true)
	const [isOnExpertise, setIsOnExpertise] = React.useState(false);
	const [test, setTest] = useState(false);
	// const imgRef = useRef(null)
	// const [upImg, setUpImg] = useState(null)
	// const [cropImage, setCropImage] = useState(null)
	const [showCropedImage, setShowCropedImage] = useState(false)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [imgIndex, setImgIndex] = useState(0)
	// const [rateCheck, setRateCheck] = useState(0)
	const [videoUrl, setVideoUrl] = useState("")
	const [finalImage1, setFinalImage1] = useState(null)
	const [finalImage2, setFinalImage2] = useState(null)
	const [finalImage3, setFinalImage3] = useState(null)
	const [openAlert, setOpenAlert] = React.useState(false);
	const [isChanged, setIsChanged] = React.useState(false);
	const [proceedWithUpdate, setProceedWithUpdate] = React.useState(false);
	// const [showUsernameError, setShowUsernameError] = React.useState(false);
	const [state, setState] = useState({
		bio: "",
		rate: "",
		tags: [],
		profile_media_urls: [],
		enable_review_notification: false,
		enable_invite_notification: false,
		video_url: "",
	});
	const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
	const updateProfileResponse = useSelector(
		({ EditProfile }) => EditProfile.UpdateProfileReducer
	);
	let updateProfileResponseData = updateProfileResponse.data;
	const errMsg = updateProfileResponse.errMsg;
	React.useEffect(() => {
		if (isFirstRenderRef.current) {
			isFirstRenderRef.current = false
			return
		}
		if (updateProfileResponseData) {
			if (updateProfileResponseData.status === "Success") {
				dispatch(Actions.resetProfileReducer());
				if (proceedWithUpdate) {
					history.push("/profile", "profile-updated");
				}
				//  else {
				// 	const {
				// 		bio,
				// 		rate,
				// 		tags,
				// 		profile_media_urls,
				// 		enable_review_notification,
				// 		enable_invite_notification,
				// 		video_url,
				// 	} = userData;
				// 	setState({
				// 		bio,
				// 		rate: rate,
				// 		tags,
				// 		profile_media_urls,
				// 		enable_review_notification,
				// 		enable_invite_notification,
				// 		video_url,
				// 	});
				// }
			}
		}
	}, [updateProfileResponseData]);
	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: false }));
		dispatch(BaseActions.getUserData());
		const {
			bio,
			rate,
			tags,
			username,
			full_name,
			enabledUsername,
			profile_media_urls,
			enable_review_notification,
			enable_invite_notification,
			video_url,
		} = userData;
		setState({
			bio,
			rate: rate,
			tags,
			username,
			full_name,
			enabledUsername,
			showUsername: enabledUsername ? "username" : "fullname",
			profile_media_urls,
			enable_review_notification,
			enable_invite_notification,
			video_url,
		});
		setFinalImage1(profile_media_urls[0])
		setFinalImage2(profile_media_urls[1])
		setFinalImage3(profile_media_urls[2])
		setVideoUrl(video_url)
	}, [dispatch]);
	const {
		bio,
		rate,
		username,
		full_name,
		enabledUsername,
		showUsername,
		tags,
		profile_media_urls,
		enable_review_notification,
		enable_invite_notification,
		video_url,
	} = state;
	const handleChange = (e) => {
		setIsChanged(true)
		const { name, checked, value, type } = e.target;
		// console.log({ name, value })
		type === "checkbox"
			? setState({ ...state, [name]: checked })
			: setState({ ...state, [name]: value });
	};
	const handleTagsChange = (value) => {
		setIsChanged(true)
		setState({ ...state, tags: value })
	};
	const handleRateChange = (e) => {
		// setRateCheck(e);
		const { name, value } = e.target;
		let rate = value.substring(1)
		if (!isNaN(rate)) {
			setState({ ...state, [name]: rate });
		}
	};
	const handleNameChange = (e) => {
		setCheck(false)
		setIsChanged(true)
		const { value,
			// name, checked, type
		} = e.target;
		enabledUsername ?
			setState({ ...state, username: value }) :
			setState({ ...state, full_name: value })
	};
	const [showImage, setShowImage] = React.useState(null)
	// const [nameField, setNameField] = React.useState("");
	const handleRadioChange = (e) => {
		setIsChanged(true)
		const { name, value } = e.target;
		value === 'username' ? setState({ ...state, [name]: value, enabledUsername: true }) : setState({ ...state, [name]: value, enabledUsername: false })
		// setNameField(value)
	};
	const [imageSizeValidation, setImageSizeValidation] = useState(true)
	const [videoSizeValidation, setVideoSizeValidation] = useState(true)
	const fileHandler = async (e, i) => {
		setIsChanged(true)
		setImgIndex(i)
		const file = e.target.files[0];
		e.target.value = ''
		var blob = file.slice(0, file.size, "image/*");
		if (file.size < 4089446) {
			const options = {
				maxSizeMB: 0.4,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			const compressedFile = await imageCompression(file, options);
			setShowImage(URL.createObjectURL(compressedFile))
			setTest(!test);
			setShowCropedImage(true)
			setImageSizeValidation(true)
		}
		else {
			setImageSizeValidation(false)
			setVideoSizeValidation(true)
		}
	};
	const handleClose = () => {
		setOpen(false);
	};
	const videoHandler = async (e) => {
		setIsChanged(true)
		const file = e.target.files[0];
		e.target.value = ""
		if (file.type.includes("video")) {
			var blob = file.slice(0, file.size, "video/*");
			// if (file.size < 12478054) {
			const link = `${userData._id}/video/${new Date().getTime()}`;
			const newFile = new File([blob], link, { type: "video/*" });
			const url = await uploadingFile(newFile);
			// setState({ ...state, video_url: url });
			// alert
			setOpenAlert(true)
			setVideoUrl(url)
			setVideoSizeValidation(true)
			// }
			// else {
			// 	setVideoSizeValidation(false)
			// 	setImageSizeValidation(true)
			// 	// setTimeout(() => setVideoSizeValidation(true), 5000);
			// }
		} else
			dispatch(
				// BaseActions.showAlert({
				// 	variant: "error",
				// 	message: "File type not allowed",
				// })
			);
	};
	const uploadingFile = async (file) => {
		dispatch(BaseActions.showLoading(true));
		try {
			const data = await uploadFile(file, config);
			dispatch(BaseActions.hideLoading());
			return data.location;
		} catch (error) {
			// console.log(error);
			dispatch(BaseActions.hideLoading());
		}
	};
	const handleBackToEdit = () => {
		setIsOnExpertise(true);
	};
	const backToEdit = () => {
		setIsOnExpertise(false);
	};
	const handleSubmit = () => {
		// setState({ ...state, full_name: updatedFullName })
		if (rate < 5 || rate > 999) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Your rate must be between $5 and $999.",
			// 	})
			// );
			return;
		} else if (enabledUsername && (username?.length < 2 || username?.length > 20)) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Username length should be 2 - 20",
			// 	})
			// );
			return;
		} else if (showUsername === "username" && (username?.length === 0 || username?.length < 2 || username?.length > 20)) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Username length should be 2 - 20",
			// 	})
			// );
			return;
		}
		else if (full_name?.length < 2 || full_name?.length > 60) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Full Name  length should be 2 - 60",
			// 	})
			// );
			return;
		}
		else if (username?.indexOf(' ') >= 0) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Full Name  length should be 2 - 60",
			// 	})
			// );
			return;
		}
		else if (firstName.length < 2 || secondName.length < 2) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Full Name  length should be 2 - 60",
			// 	})
			// );
			return;
		}
		else if (/\d/.test(full_name)) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Full Name  length should be 2 - 60",
			// 	})
			// );
			return;
		}
		else {
			setCheck(true)
			dispatch(Actions.updateProfile({
				...state,
				video_url: videoUrl !== "" ? videoUrl : video_url,
				full_name: showUsername === "username" ? full_name : updatedFullName ? updatedFullName : full_name
			}));
		}
		setProceedWithUpdate(true)
		// history.push("/home");
	};
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(3)
	const [croppedImage, setCroppedImage] = useState(null)
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])
	const [rotationValue, setRotationValue] = React.useState(0);
	const showCroppedImage = useCallback(async () => {
		try {
			setShowCropedImage(false)
			const croppedImage = await getCroppedImg(
				showImage,
				croppedAreaPixels,
				rotationValue
			)
			var blob = croppedImage.slice(0, croppedImage.size, "image/*");
			const link = `${userData._id}/pic${imgIndex}/${new Date().getTime()}`;
			const newFile = new File([blob], link, { type: "image/*" });
			setCroppedImage(croppedImage)
			const options = {
				maxSizeMB: 0.4,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			const compressedFile = await imageCompression(newFile, options);
			const url = await uploadingFile(compressedFile);
			profile_media_urls[imgIndex] = url
			if (imgIndex === 0) {
				setFinalImage1(URL.createObjectURL(compressedFile))
			} else if (imgIndex === 1) {
				setFinalImage2(url)
			} else if (imgIndex === 2) {
				setFinalImage3(url)
			}
		} catch (e) {
			// console.error(e)
		}
	}, [croppedAreaPixels, rotationValue])
	const handleModalClose = () => {
		setOpen(false);
		history.push("/profile")
	};
	useEffect(() => {
		if (full_name) {
			const words = full_name.split(' ');
			if (words.length === 3) {
				if (words[1] === "") {
					words[1] = words[2]
				}
			}
			if (words.length === 4) {
				if (words[2] === "") {
					words[2] = words[3]
				}
				if (words[1] === "") {
					words[1] = words[2]
				}
			}
			const first_name = words[0];
			const second_name = words[1];
			setFirstName(first_name)
			setSecondName(second_name)
			setFullName(full_name)
		}
	}, [full_name])
	const [firstName, setFirstName] = useState("");
	const [secondName, setSecondName] = useState("");
	const [fullName, setFullName] = useState("");
	const [updatedFullName, setUpdatedFullName] = useState("")
	const [check, setCheck] = useState(false)
	const handleFullName = (e) => {
		setIsChanged(true)
		const words = full_name.split(' ');
		// const first_name = words[0];
		// const second_name = words[1];
		if (e.target.name === "firstname") {
			setFirstName(e.target.value)
			const fname = e.target.value.concat(" ".concat(secondName))
			setUpdatedFullName(fname)
			setState({ ...state, full_name: fname })
		}
		else if (e.target.name === "secondname") {
			setSecondName(e.target.value)
			const fname = firstName.concat(" ".concat(e.target.value))
			setUpdatedFullName(fname)
			setState({ ...state, full_name: fname })
		}
	}
	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert(false);
	};
	const handleBack = () => {
		if (isChanged) {
			setOpen(true)
		} else {
			history.push("/profile")
		}
	}
	const childComponentMemo2 = React.useMemo(() =>
		<ImageCard
			fileHandler={fileHandler}
			classes={classes}
			i={1}
			img={finalImage2 ? finalImage2 === "https://narsunprojects.com:3001/uploads/default_pic.png" ? dummy : finalImage2 : dummy}
			type="image/*"
		/>, [finalImage2]);
	const childComponentMemo3 = React.useMemo(() =>
		<ImageCard
			fileHandler={fileHandler}
			classes={classes}
			i={2}
			img={finalImage3 ? finalImage3 === "https://narsunprojects.com:3001/uploads/default_pic.png" ? dummy : finalImage3 : dummy}
			type="image/*"
		/>, [finalImage3]);
	return (
		<>
			<div className={classes.gradient}></div>
			{!isOnExpertise ? (
				<Paper
					className={classes.container}
					style={{
						backgroundImage: `url('${finalImage1 === "https://narsunprojects.com:3001/uploads/default_pic.png" || finalImage1 === "" ? DummyImage : finalImage1}')`,
					}}
				>
					{
						showCropedImage &&
						<Box className={classes.imgOuterContainer}>
							<Box style={{
								width: "100%",
								height: "350px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}>
								<Box className={classes.cropContainer}>
									<Cropper
										image={showImage}
										crop={crop}
										zoom={zoom}
										rotation={rotationValue}
										aspect={4 / 4}
										onCropChange={setCrop}
										onCropComplete={onCropComplete}
										onZoomChange={setZoom}
									/>
								</Box>
							</Box>
							<Box padding="0 20px 0 20px" width="100%">
								<div
									style={{
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										display: "flex",
									}}
									className={classes.root}
								>
									<div className={classes.rotateBtn} onClick={() => setRotationValue(rotationValue - 90)}><span style={{ color: "#fff" }}><RotateLeft /></span></div>
									<Box padding="0 20px 0 20px" width="100%">
										<div className={classes.root}>
											<Slider value={zoom} min={1} max={5} step={0.1} onChange={(e, newValue) => setZoom(newValue)} aria-label="pretto slider" />
										</div>
									</Box>
									<div className={classes.rotateBtn} onClick={() => setRotationValue(rotationValue + 90)}><span style={{ color: "#fff" }}><RotateRight /></span></div>
								</div>
							</Box>
							<Box className={classes.btnContainer} position="absolute" display="flex" justifyContent="space-between" bottom={0} padding="0 20px 0 20px" width="100%">
								<div className={classes.root}>
									<Button style={{ backgroundColor: "#080A09", border: '1px solid silver' }} onClick={() => setShowCropedImage(false)}>Cancel</Button>
								</div>
								<div className={classes.root}>
									<Button onClick={showCroppedImage}>Save</Button>
								</div>
							</Box>
						</Box>
					}
					<Box className={classes.cardContainer} style={{ position: 'relative' }}>
						<Box className={classes.inline} style={{ width: '100%', zIndex: '9' }}>
							<ArrowBackIosIcon
								className={classes.backButton}
								onClick={handleBack}
							/>
							<Typography className={classes.editButton} onClick={handleSubmit}>
								Save
							</Typography>
						</Box>
						<label htmlFor="button-file">
							<Typography
								className={
									profile_media_urls[0] !==
										{ DummyImage }
										? classes.changeButton
										: classes.otherChangeButton
								}
							>
								<img alt="icon" src={"./assets/images/dummyImage.svg"} width={14} />
								{profile_media_urls[0] !==
									{ DummyImage }
									? " Change"
									: " Add"}
							</Typography>
						</label>
						<input
							accept="image/*"
							onChange={(e) => fileHandler(e, 0)}
							style={{ display: "none" }}
							id="button-file"
							type="file"
						/>
					</Box>
					{imageSizeValidation === false ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', position: 'absolute', fontSize: '14px', marginLeft: '73px', display: videoSizeValidation === false ? "none" : null }}>*Image size should be less 3MB</span> : null}
					{videoSizeValidation === false ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', position: 'absolute', fontSize: '14px', marginLeft: '73px', display: imageSizeValidation === false ? "none" : null }}>*Video size should be less 12MB</span> : null}
					<Box className={classes.body}>
						<Box className={classes.main}>
							{
								profile_media_urls.length &&
								childComponentMemo2
							}
							{
								profile_media_urls.length &&
								childComponentMemo3
							}
							{
								video_url || videoUrl ?
									<div className="player-wrapper">
										<label htmlFor={"video"}>
											{
												isIOS ?
													<ReactPlayer light={"https://images.unsplash.com/photo-1541363111435-5c1b7d867904?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZHVtbXl8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=150&q=80"} playsinline url={video_url ? video_url : videoUrl ? videoUrl : ""} width="87px" height="87px" />
													:
													<ReactPlayer playsinline url={videoUrl ? videoUrl : ""} width="87px" height="87px" />
											}
											<Typography
												className={
													classes.changePicButton
													// classes.otherChangePicButton
												}
											>
												<img alt="icon" src={Pics.videoPlaceholder} width={11} />
												Change
											</Typography>
										</label>
										<input
											accept="video/*"
											onChange={videoHandler}
											style={{ display: "none" }}
											id={"video"}
											type="file"
										/>
									</div>
									:
									<ImageCard
										fileHandler={videoHandler}
										classes={classes}
										i={3}
										img={video_url && Pics.videoThumbnail}
										type="video/*"
									/>
							}
							<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
								<Alert onClose={handleCloseAlert} severity="success">
									Video uploaded successfully!
								</Alert>
							</Snackbar>
						</Box>
						<Box marginTop={isMobile ? "15px" : "30px"}>
							<Typography variant="caption">{enabledUsername ? "Username" : "Full name"}   </Typography>
							<Box marginTop="15px">
								<Box position="relative" marginTop="15px" className={classes.textAreaContainer}>
									{
										enabledUsername ?
											<input
												type="text"
												name="username"
												value={username ? username : ''}
												onChange={handleNameChange}
												className={clsx(classes.transparentInput, classes.rateInput)}
												placeholder="Example: SuperMn123"
												disabled={enabledUsername ? false : true}
											/>
											:
											<input
												type="text"
												name="username"
												value={fullName ? fullName : ""}
												onChange={handleNameChange}
												className={clsx(classes.transparentInput, classes.rateInput)}
												placeholder="Example: SuperMn123"
												disabled={showUsername === "fullname" ? true : false}
											/>
									}
								</Box>
								{
									errMsg && check && "Username already exists" &&
									<p style={{
										// position: "absolute",
										// bottom: 0,
										// left: 0,
										// marginTop: "-5px",
										// padding: "5px",
										fontFamily: "cerebri-regular",
										color: 'rgb(203, 58, 58)',
										fontSize: '14px',
										paddingLeft: '5px'
									}}>
										*This Username is already in use. Please choose a <br /> different one.
									</p>
								}
							</Box>
							<Box marginTop={isMobile ? "15px" : "30px"} display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="caption">Display:</Typography>
								<Box className={classes.inline}>
									<RadioGroup name="showUsername" value={"" + showUsername} onChange={handleRadioChange}>
										<Box display="flex">
											<FormControlLabel value="fullname" control={
												<RadioButton
													classes={{
														root: classes.root,
														checked: classes.checked,
														disabled: classes.disabled,
													}}
													color="secondary"
													size="large"
													checkedIcon={
														<div className={clsx(classes.icon, classes.checkedIcon)}>
															<span className={clsx(classes.blueDot)} />
														</div>
													}
												/>
											} label="Full name" />
											{showUsername === "username" && (username?.length === 0 || username?.length < 2 || username?.length > 20) ? (<span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: isMobile ? "-10px" : '-20px' }}>*Username length should be 2 - 20</span>) : null}
											{showUsername === "username" && username?.indexOf(' ') >= 0 ? (<span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '-20px', display: username?.length === 0 ? "none" : "" }}>*There should be no space in username</span>) : null}
											{showUsername === "fullname" && /\d/.test(full_name) ? (<span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '-20px' }}>*Full name should not contain numbers</span>) : null}
											<FormControlLabel value="username" control={
												<RadioButton
													classes={{
														root: classes.root,
														checked: classes.checked,
														disabled: classes.disabled,
													}}
													color="secondary"
													size="large"
													checkedIcon={
														<div className={clsx(classes.icon, classes.checkedIcon)}>
															<span className={clsx(classes.blueDot)} />
														</div>
													}
												/>
											} label="Username" />
										</Box>
									</RadioGroup>
								</Box>
							</Box>
							<Box marginTop={isMobile ? "15px" : "30px"}>
								<Typography variant="caption">First Name</Typography>
								<Box marginTop="15px">
									<Box position="relative" marginTop="15px" className={classes.textAreaContainer}>
										<input
											type="text"
											name="firstname"
											value={firstName}
											// onChange={handleNameChange}
											onChange={handleFullName}
											className={clsx(classes.transparentInput, classes.rateInput)}
											placeholder="Jhon"
										/>
									</Box>
								</Box>
							</Box>
							{firstName.length < 2 ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '2px', display: firstName.length > 2 ? "none" : null }}>*Minimum 2 characters.</span> : null}
							{firstName.length > 30 ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '2px', display: firstName.length < 29 ? "none" : null }}>*Should not be greater than 30 characters</span> : null}
							<Box marginTop={isMobile ? "15px" : "30px"}>
								<Typography variant="caption">Last Name</Typography>
								<Box marginTop="15px">
									<Box position="relative" marginTop="15px" className={classes.textAreaContainer}>
										<input
											type="text"
											name="secondname"
											value={secondName}
											// onChange={handleNameChange}
											onChange={handleFullName}
											className={clsx(classes.transparentInput, classes.rateInput)}
											placeholder="Jhon"
										/>
									</Box>
								</Box>
							</Box>
						</Box>
						{secondName.length < 2 ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '2px', display: secondName.length > 2 ? "none" : null }}>*Minimum 2 characters.</span> : null}
						{secondName.length > 30 ? <span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '2px', display: secondName.length < 29 ? "none" : null }}>*Should not be greater than 30 characters</span> : null}
						<Box marginTop={isMobile ? "15px" : "30px"}>
							<Typography variant="caption">Bio</Typography>
							<Box marginTop="15px">
								<Box marginTop="15px" className={classes.textAreaContainer}>
									<textarea
										type="text"
										maxLength={200}
										name="bio"
										value={bio}
										onChange={handleChange}
										className={clsx(classes.transparentInput, classes.bioInput)}
									/>
								</Box>
							</Box>
						</Box>
						<Box marginTop={isMobile ? "15px" : "30px"}>
							<Typography variant="caption">Rate</Typography>
							<Box marginTop="15px" className={classes.textAreaContainer} style={{ display: 'flex', flexDirection: 'row' }}>
								<span style={{ fontSize: '24px', color: 'white', fontFamily: 'cerebri-semibold' }}>$</span>
								<input
									// type="number"
									name="rate"
									value={Math.round(rate) === 0 ? " " : " " + Math.round(rate)}
									onChange={handleRateChange}
									className={clsx(classes.transparentInput, classes.rateInput)}
									placeholder="5.00"
									style={{ width: '62%' }}
									maxLength="4"
								/>
								<span style={{ fontSize: '24px', color: 'white', fontFamily: 'cerebri-semibold', color: '#808080' }}>/ Quartr</span>
							</Box>
							{rate > 999 || rate < 5 ? (<p style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px' }}>*Your rate must be between $5 and $999.</p>) : null}
						</Box>
						<Box marginTop={isMobile ? "15px" : "30px"}>
							<Box className={classes.inline}>
								<Typography variant="caption">Expertise</Typography>
								<ArrowForwardIosIcon
									className={classes.expertiseButton}
									// onClick={() => history.push("/expertise")}
									onClick={() => setIsOnExpertise(state.tags)}
								/>
							</Box>
							<Box marginTop="15px" className={classes.expertiseContainer}>
								{tags?.map((t, i) => (
									<Typography key={i} className={classes.tags}>
										#{t.expertise}({t.years} years)
									</Typography>
								))}
							</Box>
						</Box>
						<Modal
							open={open}
							onClose={handleModalClose}
							className={classes.modal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<Box className={classes.modalContainer}>
								<Box padding={isAndroid ? "50px" : "40px"} width={isMobile ? "97%" : "100"}> {/**370 */}
									<Typography variant="body2">Save Changes</Typography>
									<Box marginTop="20px">
										<Typography variant="caption">
											Do you want to save changes?
										</Typography>
										<Box marginTop="auto" className={classes.modalActionContainer}>
											<Button
												onClick={handleModalClose}
												variant="outlined"
												color="default"
												style={{ width: "46%" }}
											>
												{"No"}
											</Button>
											<Button
												onClick={handleSubmit}
												color="primary"
												style={{ width: "46%" }}
											>
												{"Yes"}
											</Button>
										</Box>
									</Box>
								</Box>
							</Box>
						</Modal>
						<Box marginTop={isMobile ? "15px" : "30px"} marginBottom={isMobile ? "0px" : "50px"}>
							<Typography variant="body2">Notifications</Typography>
							<Box marginTop="15px">
								<Box margin="0 10px" className={classes.inline}>
									<Typography variant="caption">Reviews</Typography>
									<MySwitch
										isDark
										checked={enable_review_notification}
										onChange={handleChange}
										name="enable_review_notification"
									/>
								</Box>
							</Box>
							<Box marginTop="15px">
								<Box margin="0 10px" className={classes.inline}>
									<Typography variant="caption">Referrals</Typography>
									<MySwitch
										isDark
										checked={enable_invite_notification}
										onChange={handleChange}
										name="enable_invite_notification"
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Paper>
			) : (
				<Expertise handleTagsChange={handleTagsChange} handleBackToEdit={handleBackToEdit} backToEdit={backToEdit} tags={state.tags} />
			)
			} {" "}
		</>
	);
}
export default withReducer("EditProfile", reducer)(withRouter(EditProfile));

import React, { useState } from "react";
import { withRouter } from "react-router";
import { Box, Typography, withStyles, Slide, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import UserCard from "./subComponents/userCard";
import QuartrTimer from "./subComponents/quartrTimer";
import NextQuartr from "./subComponents/nextQuartr";
import Header from "./subComponents/header";
import QuartrEnded from "components/videoCall/subComponents/quartrEnded";
import { homeRedirect } from "@utils";
// import { homeRedirect, initFirebase } from "@utils";
import useDeepCompareEffect from "use-deep-compare-effect";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
// import Carousel from "react-multi-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SocketClass from "@utils/socketService";
// import CountdownTimer from "./subComponents/countdownTimer";
import Slider from "react-slick";
import axios from 'axios'
import { pasteAuthHeaders } from '@utils'
import moment from "moment";
import { isAndroid, isIOS } from "react-device-detect";
// let socket = SocketClass.getSocket();
const useStyles = makeStyles((theme) => ({
	timer: {
		backgroundColor: "#09161C",
		borderRadius: "6px",
		// height: "40px",
		// width:'100%',
		margin: "0 20px",
		padding: "1px 10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& > .image": {
			height: "46px",
			width: "46px",
			borderRadius: "4px",
			border: "1px solid white",
		},
		"& > .timer": {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginLeft: "17px",
		},
		"& > .button": {
			backgroundColor: "#31A7DB",
			borderRadius: "6px",
			height: "28px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			width: "100%",
			maxWidth: "65px",
		},
	},
	popup: {
		fontSize: 14,
		fontFamily: "cerebri-regular",
		backgroundColor: "#CB3A3A",
		borderRadius: 6,
		padding: "10px 20px 10px 10px",
		margin: "0 20px",
		position: "relative",
		color: "white",
		zIndex: "90"
	},
	cross: {
		stroke: "#CB3A3A",
		strokeWidth: 2,
		position: "absolute",
		right: 5,
		top: 5,
		fontSize: 16,
		cursor: "pointer",
	},
	tags: {
		fontSize: 12,
		backgroundColor: "#09161C",
		fontFamily: "cerebri-regular",
		borderRadius: 6,
		padding: "5px 10px",
		margin: "0 10px 10px 0",
	},
	overflowX: {
		overflowX: "auto",
		display: "flex",
		flexDirection: "row",
		padding: "0 20px",
	},
	textContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: "30px 20px 0px 20px",
		[theme.breakpoints.down("sm")]: {
			padding: "5px 20px 0px 20px",
		}
	},
	pointer: {
		cursor: "pointer",
	},
	lastUserSection: {
		marginBottom: '7rem',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '3rem',
		},
	},
	slideShow: {
		padding: "0 0 0 10px"
	},
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		position: 'fixed',
		zIndex: 10000000000000,
		top: 0,
		left: 0,
		width: '100%',
		height: '91vh',
		[theme.breakpoints.down('xs')]: {
			height: '100vh',
		},
	},
	confirmWrapper: {
		backgroundColor: '#111111',
		padding: '2rem',
		position: 'absolute',
		zIndex: 1000,
		bottom: 0,
		left: 0,
		width: '100%',
	},
	cancelHeading: {
		color: '#31A7DB',
		fontWeight: 500,
		marginBottom: '1rem',
		fontSize: 16,
		fontFamily: 'Cerebri-SemiBold'
	},
	cancelConfirmText: {
		color: '#808080',
		marginBottom: '2rem',
	},
}));
const PopupBtn = withStyles({
	root: {
		height: 42,
		'& .MuiButton-label': {
			fontSize: 18,
		},
	},
})(Button)
function Home({ history, ...props }) {
	const [modal, setModal] = useState(true);
	const [user, setUser] = useState(true);
	const [isFetch, setIsFetch] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const homeResponse = useSelector(({ Home }) => Home.GetHomepageReducer);
	// const timerResponse = useSelector(
	// 	({ BaseReducer }) => BaseReducer.TimerReducer
	// );
	// By Shahroze \/\/\/\/\/\/\/\/\/\/
	const [userReported, setUserReported] = useState(false);
	const [userBlocked, setUserBlocked] = useState(false);
	const [username, setUsername] = useState("")
	const [hostAppointments, setHostAppointments] = useState([])
	const [attendeeAppointments, setAttendeeAppointments] = useState([])
	const [pageCount, setPageCount] = useState(1)
	const [nextAppointment, setNextAppointment] = useState(null)
	const [quartersLength, setQuartersLength] = useState(0)
	const [quarters, setQuarters] = useState({
		hosting: {
			appointments: [],
			page: 0,
			totalPages: 1,
		},
		attending: {
			appointments: [],
			page: 0,
			totalPages: 1,
		}
	})
	React.useEffect(() => {

		//   const dispatchRedux = useDispatch();
		// initFirebase(dispatch);
		if (history.location && history.location.state) {
			if (history.location.state.response === "user-reported") {
				dispatch(BaseActions.updateLayout({ bottomNav: false }));
				setTimeout(() => {
					setUserReported(true)
					setUsername(history.location.state.name)
					history.location.state = ""
					dispatch(BaseActions.updateLayout({ bottomNav: true }));
				}, 3000);
			}
			if (history.location.state.response === "user-blocked") {
				dispatch(BaseActions.updateLayout({ bottomNav: false }));
				setTimeout(() => {
					setUserBlocked(true)
					setUsername(history.location.state.name)
					history.location.state = ""
					dispatch(BaseActions.updateLayout({ bottomNav: true }));
				}, 3000);
			}
		}
	}, [history.location.state])
	// By Shahroze /\/\/\/\/\/\/\/\/\/\
	const ratings = useSelector(({ BaseReducer }) => BaseReducer.UserReducer.rate_your_call);
	const currentQuarter = useSelector(({ Splash }) => Splash.CurrentQuartrStateReducer?.data?.data)
	const {
		available_now: availableUsers,
		new_users: newUsers,
		recently_viewed: recentlyViewedUsers,
		suggested: suggestedUsers,
	} = homeResponse.data?.data || {};
	React.useLayoutEffect(() => {
		const { data } = JSON.parse(localStorage.getItem("quarterlyUser"));
		homeRedirect(data, history).then(() => {
			setUser(data);
			setIsFetch(true);
			// dispatch(Actions.getHomepage());
			dispatch(BaseActions.exitFullScreen());
			dispatch(
				BaseActions.updateLayout({ bottomNav: true, fullScreen: false })
			);
		});
	}, [dispatch, history]);
	React.useEffect(() => {
		props.setNotification()
		dispatch(BaseActions.hideMessage());
	}, []);
	React.useEffect(() => {
		setTimeout(() => {
			if (history.location.data && history.location.data.endCallPopup) {
				dispatch(
					BaseActions.showMessage(
						<QuartrEnded
							name={history.location.data.endCallPopup}
							handleClose={() => {
								dispatch(BaseActions.hideMessage());
								ratings.length > 0 && history.push('/rating')
							}}
						/>
					)
				);
			}
		}, 500);
	}, []);
	React.useEffect(() => {
		if (isFetch) {
			dispatch(Actions.getHomepage());
		}
	}, [isFetch, dispatch]);
	if (currentQuarter?.remaing_seconds) {
		history.replace(`/quartr/${currentQuarter._id}`)
	}

	window.history.replaceState(null, '')

	const getUpcomingQuarters = () => {
		const url = process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING
		return axios.post(`${url}appointments/get-appointments-by-date`)
			.then((res) => {

				setNextAppointment(res.data.data.appointments)
			}).catch((err) => {
				console.log({ err })
			}).finally(() => {
				dispatch(BaseActions.hideLoading())
			})
	}

	React.useEffect(() => {
		getUpcomingQuarters()
	}, [])

	const timerResponse = useSelector(
		({ BaseReducer }) => BaseReducer.TimerReducer
	);

	return (
		<Box style={{ position: "relative" }}>
			<div style={{
				backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
				height: "20px",
				width: "100%",
				position: "absolute", bottom: 0
			}}></div>
			<Header />
			{
				timerResponse.state ?
					<QuartrTimer /> :
					nextAppointment ?
						<NextQuartr nextAppointment={nextAppointment} /> :
						!user.is_verified && (
							<>
								{modal && (
									<Typography variant="body2" className={classes.popup}>
										{/* Thanks for signing up, please verify your {user.email} email and
				  confirm your registeration. */}
										Please verify your email in order to enjoy full features of Quartrly.
										<CloseIcon
											className={classes.cross}
											onClick={() => setModal(false)}
										/>
									</Typography>
								)}
							</>
						)
			}
			<Box
			// marginTop="10px"
			// padding="20px 20px 0px 20px"
			// className={classes.overflowX}
			>
				{/* {user.tags?.map((t, i) => (
					<Typography variant="body2" key={i} className={classes.tags}>
						{t.expertise}
					</Typography>
				))} */}
			</Box>
			<UserSection
				title="Recently viewed"
				param="recently_viewed"
				users={recentlyViewedUsers}
				sliderSpeedPercentage={0.5}
			/>
			<UserSection
				title="Suggestions"
				param="suggested"
				users={suggestedUsers}
				sliderSpeedPercentage={0.75}
			/>
			<UserSection
				title="Newly registered profiles"
				param="new_users"
				users={newUsers}
			/>
			<UserSection
				param="available_now"
				title="Available now"
				users={availableUsers}
				className={classes.lastUserSection}
				sliderSpeedPercentage={0.5}
			/>
			<Slide direction='up' in={userReported}>
				<div className={classes.backdrop} style={{ display: userReported ? 'inherit' : 'none' }}>
					<div className={classes.confirmWrapper}>
						<Typography variant='subtitle1' className={classes.cancelHeading}>User Reported</Typography>
						<Typography variant='subtitle2' className={classes.cancelConfirmText}>{username} has been reported successfully.</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<PopupBtn
								onClick={() => setUserReported(false)}
								fullWidth
							>
								Ok
							</PopupBtn>
						</div>
					</div>
				</div>
			</Slide>
			<Slide direction='up' in={userBlocked}>
				<div className={classes.backdrop} style={{ display: userBlocked ? 'inherit' : 'none' }}>
					<div className={classes.confirmWrapper}>
						<Typography variant='subtitle1' className={classes.cancelHeading}>User Blocked</Typography>
						<Typography variant='subtitle2' className={classes.cancelConfirmText}>{username} has been blocked successfully.</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isIOS || isAndroid ? "120px" : "40px" }}>
							<PopupBtn
								onClick={() => setUserBlocked(false)}
								fullWidth
							>
								Ok
							</PopupBtn>
						</div>
					</div>
				</div>
			</Slide>
		</Box>
	);
}
export default withReducer("Home", reducer)(withRouter(Home));
const UserSection = withRouter(
	React.memo(({ users, title, buttonText, history, param, className, sliderSpeedPercentage = 1 }) => {
		const classes = useStyles();
		const [slidesToShow, setSlidesToShow] = useState(2)
		let slicedUsers = (usersArray) => {
			if (usersArray.length > 5) {
				return usersArray.slice(0, 5);
			} else {
				return usersArray;
			}
		};
		const handleNavigate = (path, data) => {
			history.push({ pathname: path, state: data });
		};
		let renderUsers = slicedUsers(users).map((user, i, { length }) => {
			if (user.rate > 0) {
				return <div
					style={{ cursor: "pointer" }}
					onClick={() => handleNavigate(`/userprofile/${user.user_code}`, user)}
				>
					<UserCard fromHomeScreen user={user} key={i} />
				</div>
			}
		});
		const length = users.filter(ind => ind.rate > 0).length
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: length < 2 ? 1 : 2,
			slidesToScroll: 1,
			autoplay: true,
			speed: parseInt(2000 / sliderSpeedPercentage, 10),
			autoplaySpeed: 0,
			cssEase: "linear",
			arrows: false,
		};
		return (
			<>
				{users.length > 0 && (
					<>
						<Box className={classes.textContainer}>
							<Typography variant="body2">{title}</Typography>
							<Typography
								variant="body1"
								style={{ cursor: "pointer" }}
								onClick={() =>
									history.push({
										pathname: `/seeAll/${param}`,
									})
								}
							>
								{buttonText}
							</Typography>
						</Box>
						<Box className={`custom ${className} ${renderUsers.length < 3 && classes.slideShow}`}>
							<Slider {...settings}>
								{renderUsers}
							</Slider>
						</Box>
					</>
				)}
			</>
		);
	})
);
UserSection.defaultProps = {
	users: [], //[1, 2, 3, 4, 5, 6, 7, 8, 2, 2, 2, 2, 2, 2, 2],
	title: "",
	buttonText: "See all",
};

import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import { isIOS } from "react-device-detect";

const SetYourAvailabilityMessage = ({ handleClose }) => {

  const dispatch = useDispatch();
  dispatch(
    BaseActions.updateLayout({ bottomNav: true })
  );

  const handleOk = () => {
    handleClose && handleClose();
    dispatch(
      BaseActions.updateLayout({ bottomNav: true })
    );
  }
  return (
    <Box padding="30px" marginBottom="40px">
      <Typography variant="body2">Set your availability here</Typography>
      <Box marginTop="20px" paddingBottom="20px">
        <Typography variant="caption">
          Define what days and times you are available to do Quartrly video call.
        </Typography>
      </Box>

      <Box paddingTop="40px">
        <Button
          onClick={handleOk}
          fullWidth
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
};

export default SetYourAvailabilityMessage;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { InputField } from "@base";
import { Spinner } from "@base";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "cerebri-regular",
    fontSize: "18px",
    "& > *": { color: "white" },
    "&:before": {
      borderBottom: ".5px solid #808080",
    },
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  underline: {
    "&:hover": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: ".5px solid #808080 !important",
        },
      },
    },
  },
  inputBase: {
    paddingLeft: "8.5px",
    paddingRight: "8.5px",
    paddingTop: "13px",
    height: "16px",
  },
  label: {
    fontSize: "50px",
  },
  bottomBtnContainer: {
    // marginBottom: 10,
    width: "100%",
    padding: "20px"
  },
  loginContainer: {
    margin: "-60px 20px 0",
    padding: "10px",
    [theme.breakpoints.only("sm")]: {
      margin: isMobile ? "76px 20px 200px" : "66px 20px 0"
    },
    [theme.breakpoints.only("xs")]: {
      margin: isMobile ? "76px 20px 200px" : "66px 20px 0"
    },
  }
}));
const Login = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(false);
  const loginResponse = useSelector(({ Login }) => Login.SubmitLoginReducer);
  const loginResponseData = loginResponse.data;
  console.log(loginResponseData)
  const isLoading = loginResponse.isLoading;
  const errMsg = loginResponse.errMsg;
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ fullScreen: false }));
    if (loginResponseData) {
      if (loginResponseData.status === "Success") {
        // setOpen(true);
        if (history.location?.state?.user) {
          history.push({ pathname: `/userprofile/${loginResponseData.data.data.user_code}` })
        } else {
          history.push("/home");
        }
      }
    }
  }, [loginResponseData]);
  const handleLogin = async (val) => {
    dispatch(await Actions.loginSubmit(val));
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={async (values) => {
        const { password, ...rest } = values;
        let modifiedValue = {
          ...rest,
          password: password,
        };
        handleLogin(modifiedValue);
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .trim()
          .min(8, "*Minimum 8 characters.")
          .max(128, "*Should be less than 128 characters")
          .required("*Required"),
        email: Yup.string().email("*Invalid email address.").required("*Required*"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              position="absolute"
              justifyContent={isMobile ? "" : "space-between"}
            >
              <Box
                margin="10px 10px 0px 15px"
                display="flex"
                alignItems="center"
                paddingTop={isMobile ? "1%" : "8%"}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src="assets/images/backArrow.png"
                  alt="backArrow"
                  height="20px"
                  onClick={() => history.push('/')}
                />
                <div style={{ marginLeft: "20px" }}>
                  <Typography variant="h1">Login</Typography>
                </div>
              </Box>
              <Box className={classes.loginContainer}>
                <InputField
                  label="Email"
                  name="email"
                  error={
                    values.email && errors.email && touched.email ? errors.email :
                      errMsg && errMsg === "Email doesn't exists" ? "*This email address is not yet registered."
                        : false}
                  isValid={!errors.email && touched.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputField
                  label="Password"
                  type="password"
                  name="password"
                  error={
                    values.password && errors.password && touched.password
                      ? errors.password :
                      errMsg && errMsg === "Invalid password" ? "*Password entered is incorrect. Please try again."
                        : false
                  }
                  isValid={!errors.password && touched.password}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography variant="body1" align="right" style={{ cursor: 'pointer' }} onClick={() => { history.push('/forgotpassword') }}>
                  Trouble logging in?
                </Typography>
              </Box>
              <Box className={classes.bottomBtnContainer}>
                <Button
                  fullWidth
                  type="submit"
                  disabled={!dirty || !isValid || isLoading}
                >
                  {isLoading ? <Spinner /> : "Login"}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
export default withReducer("Login", reducer)(withRouter(Login));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from '@base/header';
import { useDispatch } from 'react-redux'
import * as BaseActions from 'store/actions'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: 'white'
  },
  flexCon: {
    display: "flex",
    flexDirection: "column",
    // padding: "20px 0px",
  },
  buttonCon: {
    // padding: "20px 0px",
  },
  questionArea: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
    padding: "20px",
  },
  optionArea: {
    display: "flex",
    cursor: "pointer",
    color: 'white',
    justifyContent: "space-between",
    padding: "10px 20px",
  },
}));
const Help = (props) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: true }))
    dispatch(BaseActions.hideMessage())
  }, [])
  const [Questions, setQuestions] = React.useState([]);
  const [noData, setNoData] = React.useState(false)
  React.useEffect(() => {
    dispatch(BaseActions.showLoading(true))
    let results;
    axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}faqs/list-faqtype`)
      .then((response) => {
        // console.log(response.data.data)
        results = response.data.data
        setQuestions(results)
      })
  }, []);
  React.useEffect(() => {
    if (Questions.length > 0) {
      dispatch(BaseActions.hideLoading());
    }
    // else {
    //   setTimeout(() => {
    //     setNoData(true)
    //     dispatch(BaseActions.hideLoading());
    //   }, 3000);
    // }
  }, [Questions])
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Header heading='Help' />
        <div className={classes.flexCon}>
          <Box className={classes.buttonCon}>
            <QuestionList title="Hosting Questions" Questions={Questions} noData={noData} handleClick={(e) => props.history.push(`/help/questions?title=${e}`)} />
            {/* <QuestionList title="Attending Questions" handleClick={() => props.history.push("/help/questions")} />
            <QuestionList title="Inviting Questions" handleClick={() => props.history.push("/help/questions")} />
            <QuestionList title="Profile Questions" handleClick={() => props.history.push("/help/questions")} /> */}
          </Box>
        </div>
        <Box style={{ position: 'absolute', bottom: '10%', width: '100%' }}>
          {/* <OptionArea title="1st time user ? " value='Watch tutorial' /> */}
          <OptionArea title="Any other questions ? " value='Click HERE' handleClick={() => props.history.push('/contactus')} />
          {/* <OptionArea title="Problem with app ? " value='Click HERE' /> */}
        </Box>
      </Box>
    </>
  );
};
export default withRouter(Help);
const QuestionList = ({ handleClick, title, Questions, noData }) => {
  // const dispatch = useDispatch()
  const passTitle = (e) => {
    // console.log(e)
    handleClick(e)
  }
  // console.log("noData", noData)
  const classes = useStyles();
  return (
    <div>
      {Questions.map((question) => (
        <Box className={classes.questionArea} onClick={() => passTitle(question.type)}>
          <Typography variant="subtitle1" style={{ fontSize: "16px", textTransform: "capitalize", fontFamily: "cerebri-regular", }}>{question.type} Questions</Typography>
          <ArrowForwardIosIcon />
        </Box>
      ))}
    </div>
  )
}
const OptionArea = ({ handleClick, value, title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.optionArea} onClick={handleClick}>
      <Typography variant="subtitle2" style={{ fontSize: "14px" }}>{title}</Typography>
      <Typography variant="subtitle1" style={{ fontSize: "14px" }}>{value}</Typography>
    </Box>
  )
}
import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const GET_HOME_PAGE = "GET_HOME_PAGE";
export const HOME_PAGE_IS_LOADING = "HOME_PAGE_IS_LOADING";
export const HOME_PAGE_FAILED = "HOME_PAGE_FAILED";
//SUBMIT LOGIN ACTION NAMES
export const GET_ALL = "GET_ALL";
export const ALL_IS_LOADING = "ALL_IS_LOADING";
export const ALL_FAILED = "ALL_FAILED";
// Helper ACTIONS:
export const homepageLoading = (ms) => ({
  type: HOME_PAGE_IS_LOADING,
  payload: ms,
});
export const homepageFailed = (ms) => ({
  type: HOME_PAGE_FAILED,
  payload: ms,
});
// Helper ACTIONS:
export const allLoading = (ms) => ({
  type: ALL_IS_LOADING,
  payload: ms,
});
export const allFailed = (ms) => ({
  type: ALL_FAILED,
  payload: ms,
});
export function getHomepage() {
  const token = localStorage.getItem("JWTtoken");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  };
  const request = axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/get-homepage`, config);
  return (dispatch) => {
    dispatch(BaseActions.showLoading(true));
    dispatch(homepageLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: GET_HOME_PAGE,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading(true));
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading(true));
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              homepageFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(homepageFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(homepageFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(homepageFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function getAll(page = 1, param) {
  let cancel;
  const token = localStorage.getItem("JWTtoken");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
    cancelToken: new axios.CancelToken(c => cancel = c)
  };
  let data = { tab: param };
  // console.log({ param })
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/see-all?page=${page}&tag=${param}`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(BaseActions.showLoading(true));
    dispatch(allLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: GET_ALL,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading(true));
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading(true));
        if (axios.isCancel(error)) {
          dispatch(allFailed(error))
          return
        }
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(allFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(allFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(allFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(allFailed(error.message));
        }
        console.log(error);
      });
  };
}

import { ColorConfig } from "../colorConfig";

let { secondaryColor } = ColorConfig;

export const MuiButtonConfig = {
  styles: {
    root: {
      height: "48px",
      width: "150px",
      borderRadius: "6px",
    },
    label: {
      textTransform: "none",
      fontFamily: "cerebri-regular",
      fontSize: "18px",
      color: secondaryColor,
    },
    outlined: {
      color: secondaryColor,
      borderRadius: "6px",
      borderColor: `${secondaryColor} !important`,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
  },
};

import React from "react";
// import Pics from "../../../../@assets";
import { Box, InputBase, makeStyles, Paper } from "@material-ui/core";
import { MySwitch } from "@base";
import SearchIcon from "@material-ui/icons/Search";
import DrawerMenu from "components/drawerMenu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as Actions from "components/signup/screens/signupForm/store/actions";
import AvailabilityDrawer from "components/availabilityDrawer";
// import { socket } from "@utils";
import SocketClass from "@utils/socketService";
import availability from "components/availability";
import { useEffect } from "react";

let socket = undefined;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: "90px",
  },
  positionFixed: {
    borderRadius: "10px 0px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    // marginTop: 0,
    backgroundColor: "#080A0A",
    display: "flex",
    padding: "20px 20px 10px 20px",
    position: "fixed",
    zIndex: "100",
    width: "100%",
    // border: "1px dashed orange",
    [theme.breakpoints.up("sm")]: {
      width: 371,
    },
    [theme.breakpoints.down("sm")]: {
      // padding: "15px 20px 0px 20px",
      padding: "23px 20px 0px 20px",
    },
  },
  dp: {
    // margin: "7px 0 0 0",
    height: "20px",
    width: "20px",
    // borderRadius: 5,
  },
  root: {
    display: "flex",
    flex: 1,
    margin: "0 10px 0 20px",
    padding: "0 5px",
    backgroundColor: "#161616",
    color: "#808080",
    alignItems: "center",
    height: 35,
  },
  input: {
    marginLeft: theme.spacing(1),
    textAlign: "center",
    color: "#808080",
    flex: 1,
  },
  backArrow: {
    color: "#FFFFFF",
    cursor: "pointer",
  },
}));
function Header({ seeAll, history, searchBar, onChange, value }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [isOn, setIsOn] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");
  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
  React.useEffect(() => {
    socket = SocketClass.getSocket();
  }, []);
  React.useEffect(() => {
    // alert(72)
    dispatch(BaseActions.getUserData());
  }, [dispatch]);

  useEffect(() => {
    // socket.emit("onprofile", {
    //   profile_id: userData && userData._id,
    // }); // id
    // setLoading(false)

    console.log("check avail", userData.available);
    if (userData.available === 1) {
      let check = false;

      console.log(userData);
      if (userData == null) {
        console.log("inital falsified");
        setIsOn(false);
      } else {
        console.log(userData.availablility);
        if (
          userData.availablility != null ||
          userData.availablility != undefined
        ) {
          userData.availablility.map((avail) => {
            const date = new Date();
            date.setDate(date.getDate());
            
            console.log("current->",date.getDate());

            if (
              new Date(avail.date).getUTCDate() ==
              date.getUTCDate()
            ) {
              check = true;
              console.log("truthified");
            }
          });
        }
      }

      if (check == false) {
        console.log("final falsifiedeee");
        setIsOn(false);
      } else {
        console.log("final truthified");
        setIsOn(true);
      }

      return;
    } else if (userData.available === 0) {
      // setLoading(false)
      let check = false;

      if (userData == null) {
        console.log("inital falsified");
        setIsOn(false);
      } else {
        if (
          userData.availablility != null ||
          userData.availablility != undefined
        ) {
          userData.availablility.map((avail) => {
            const date = new Date();
            date.setDate(date.getDate());
            console.log("current->",date.getDate());
            if (
              new Date(avail.date).getUTCDate() ==
              date.getUTCDate()
            ) {
              check = true;
              console.log("truthified");
            }
          });
        }
      }

      if (check == false) {
        console.log("final falsifiedeee");
        setIsOn(false);
      } else {
        console.log("final truthified");
        setIsOn(true);
      }

      return;
    }
  }, [userData.available]);

  const handleChange = () => {
    // setLoading(true)
    // alert('called')
    if (isOn) {
      //removes the dates that are of today's date, when button is switched off
      const hours = new Date().getUTCHours(); // setting UTC + 5 OFFSET , BY DEFUALT ITS GIVING +8
      const calc = hours % 24; //convert to 24hr format if exceeds 24 bcz of utc hours exceed 24
      const newAvailablity = [];

      if (userData.availablility != null) {
        if (calc + 5 < 9) {
          userData.availablility.map((avail) => {
            const date = new Date();
            date.setDate(date.getDate());

            console.log("GETTING",date.getUTCDate());
            
            if (
              new Date(avail.date).getUTCDate() !=
              date.getUTCDate()
            ) {
              newAvailablity.push(avail.date);
            } else {
              console.log("truthified");
            }
          });

          socket.emit("updatemyprofile", {}, (payload) => {
            console.log("acknowledgment", payload);
          });

          const payloads = newAvailablity.map((date) => {
            let stampObject = {
              appointment: true,
              attendee: false,
              date: date,
              forced: false,
              quartr_now: false,
              status: false,
              timestamp: date,
            };
            return stampObject;
          });
          dispatch(Actions.setAvailability(payloads));
          setIsOn(false);
        } else if (calc + 5 > 9 && calc + 5 < 17) {
          userData.availablility.map((avail) => {
            const date = new Date();
            date.setDate(date.getDate());

            if (new Date(avail.date).getUTCDate() != date.getUTCDate()) {
              newAvailablity.push(avail.date);
            } else {
              console.log("truthified");
            }
          });

          socket.emit("updatemyprofile", {}, (payload) => {
            console.log("acknowledgment", payload);
          });

          const payloads = newAvailablity.map((date) => {
            let stampObject = {
              appointment: true,
              attendee: false,
              date: date,
              forced: false,
              quartr_now: false,
              status: false,
              timestamp: date,
            };
            return stampObject;
          });
          dispatch(Actions.setAvailability(payloads));
          setIsOn(false);
        }
        // socket.connect();
        // socket.emit("onprofile", {
        //   profile_id: userData && userData._id,
        // }); // id
        // dispatch(
        // 	BaseActions.showAlert({
        // 		variant: "success",
        // 		message:
        // 			"This status tells others that you are not available to take Quartrly calls.",
        // 	})
        // );
        SocketClass.getOffline(userData && userData._id);
        // socket.emit("updatingmyprofile", { available: 0 });
        // socket.emit("abac", { available: 0 });
        return;
      }
    }
    dispatch(
      BaseActions.showMessage(<AvailabilityDrawer handleClose={handleClose} />)
    );
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  };

  const handleClose = () => {
    dispatch(BaseActions.hideMessage());
  };
  const handleNavigate = () => {
    !searchBar && history.push("/search");
  };
  const defaultOnChange = (event) => {
    setDefaultValue(event.target.value);
  };
  const handleDrawer = () => {
    dispatch(BaseActions.hideMessage());
    dispatch(BaseActions.showMessage(<DrawerMenu />));
  };

  // console.log(window.location.pathname)
  return (
    <Box className={classes.container}>
      <Box className={classes.positionFixed}>
        {seeAll ? (
          <ArrowBackIosIcon
            className={classes.backArrow}
            onClick={() => history.push("/home")}
          />
        ) : (
          <img
            src={"assets/Tabbar/TabbarInactive/menu.png"}
            alt={"sdfdsf"}
            className={classes.dp}
            onClick={() => handleDrawer()}
            // style={{ cursor: "pointer" }}
          />
        )}
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            onClick={() => handleNavigate()}
            // onChange={onChange ? onChange : defaultOnChange}
            inputProps={{
              style: {
                textAlign: "center",
                fontFamily: "cerebri-light",
                fontSize: "16px",
              },
              className: "header-input",
              value: value ? value : defaultValue,
              onChange: onChange ? onChange : defaultOnChange,
            }}
          />
          <SearchIcon />
        </Paper>
        {/*
        {!seeAll && (
          <MySwitch
            // isDark
            isLoading={loading}
            checked={isOn}
            handleChange={handleChange}
          />
        )}
        */}
      </Box>
    </Box>
  );
}
export default withRouter(Header);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MenuIcon from "@material-ui/icons/Menu";
import DrawerMenu from "components/drawerMenu";
import useDeepCompareEffect from "use-deep-compare-effect";
import SetYourAvailabilityMessage from "components/setYourAvailabilityMessage";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as Notifications from "store/actions";
import * as Actions from "./../../components/notifications/store/actions";
import { withRouter } from "react-router-dom";
import clsx from 'clsx'
import { Badge } from "@material-ui/core";
import { AiOutlineCalendar } from 'react-icons/ai';
import Blue from '../../@assets/navContactIcons/blue.svg'
import White from '../../@assets/navContactIcons/white.svg'
import Axios from "axios";
import { useLocation, useHistory } from 'react-router-dom'
// import * as NotificationActions from "./../notifications/store/actions";

import './bottomNav.css'

// ICONS

const MENU_ACTIVE_IC = "/assets/Tabbar/TabbarActive/menu.png";
const MENU_IC = "/assets/Tabbar/TabbarInactive/menu.png";

const HOME_IC = "/assets/Tabbar/TabbarInactive/home.png";
const HOME_ACTIVE_IC = "/assets/Tabbar/TabbarActive/home.png";

const BELL_ACTIVE_IC = "/assets/Tabbar/TabbarActive/bell.png";
const BELL_IC = "/assets/Tabbar/TabbarInactive/bell.png";

const CALANDER_ACTIVE_IC = "/assets/Tabbar/TabbarActive/calander.png";
const CALANDER_IC = "/assets/Tabbar/TabbarInactive/calander.png";

const ERROR = "/assets/Tabbar/Error@2x.png";

const useStyles = makeStyles({
	root: {
		width: "100%",
		background: "#080A0A",
		// border: "1px dashed orange"
		height: "50px",
		paddingBottom: "10px"
	},
	hidden: {
		visibility: "hidden"
	}
});
function BottomNav(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [value, setValue] = React.useState("home");
	const [isEmpty, setIsEmpty] = React.useState(false);
	const [isDefault, setIsDefault] = React.useState(true);
	const [unreadCount, setUnreadCount] = React.useState(0);

	const loc = props.location.prevLocation
	// if (loc) {
	// 	console.log(loc.prevLocationPath)
	// }

	const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);

	useDeepCompareEffect(() => {
		if (!userData.availablility) {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
		}
	}, [userData, userData.availablility]);

	const handleClose = () => {
		dispatch(BaseActions.hideMessage());
		props.history.push("/quarters");
	};
	const handleChange = (event, newValue) => {
		isDefault && setIsDefault(false);
		setValue(newValue);
	};

	const getNotification = async () => {
		const authToken = localStorage.getItem("JWTtoken");
   
		const notofications = await Axios({
		  method: "get",
		  headers:{
			Authorization:`JWT ${authToken}`
		  },
		  url: `${
			process.env.REACT_APP_ENV === "prod"
			  ? process.env.REACT_APP_BASE_URL_PROD
			  : process.env.REACT_APP_BASE_URL_TESTING
		  }notification/unread-count`,
		});
		
		setUnreadCount(notofications?.data?.unreadCount)
	}

	React.useEffect(() => {
		getNotification()
	}, [userData,props.newNotification]);

	React.useEffect(() => {
		if (props.location.pathname === "/notifications") {
			setUnreadCount(0);
			return;
		}
		if (props.location.pathname === "/home") {
			setValue("home");
			return;
		}
		if (props.location.pathname === "/quarters") {
			setValue("calender");
			return;
		} else {
			setValue(props.location.pathname.substring(1));
		}
	}, [props.location]);

	React.useEffect(() => {
		switch (value) {
			case "menu":
				props.history.push("/profile");

				break;
			case "home":
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/home") {
					return;
				}
				!isDefault && props.history.push("/home");

				break;
			case "notifications":
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/notifications") {
					return;
				}
				!isDefault && props.history.push("/notifications");

				break;
			case "calender":
				if (isEmpty) {
					if (loc && loc.prevLocationPath === "/availability") {
						dispatch(BaseActions.hideMessage());
					}
					else {
						dispatch(
							BaseActions.showMessage(
								<SetYourAvailabilityMessage handleClose={handleClose} />
							)
						);
					}
					return;
				}
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/quarters") {
					return;
				}

				props.history.push("/quarters");

				break;

			default:
				dispatch(BaseActions.hideMessage());
				break;
		}
	}, [value]);

	const showNavBar = window.location.pathname === "/signup" ||
		window.location.pathname === "/legalStuff" ||
		window.location.pathname === "/profile-image" ||
		window.location.pathname === "/rate-refer" ||
		window.location.pathname === "/username" ||
		window.location.pathname === "/expertise"
		? false : true

	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		// console.log("path", location.pathname)
		// console.log("value", value)
		if (value === "home") {
			if (location.pathname === "/notifications" && value === "home") {
				setValue("notifications")
				history.push("/notifications")
			} else if (location.pathname === "/home" && value === "home") {
				setValue("home")
			}
		}
	}, [])


	return (
		showNavBar &&
		<BottomNavigation
			{...props}
			color="secondary"
			value={value}
			onChange={handleChange}
			className={clsx(showNavBar ? `${classes.root} ${props.className}` : classes.hidden)}
		>
			<BottomNavigationAction style={{ marginBottom: '5px' }}
				value="home"
				icon={
					value === "home" && location.pathname === "/home" ? (
						<img
							style={{ height: "20px", width: "16px" }}
							src={HOME_ACTIVE_IC}
							alt="home"
						/>
					) : (
						<img
							style={{ height: "20px", width: "16px" }}
							src={HOME_IC}
							alt="homeic"
						/>
					)
				}
			/>
			<BottomNavigationAction style={{ marginBottom: '5px' }}
				value="calender"
				icon={
					<div style={{ position: "relative" }}>
						{isEmpty && (
							<img
								style={{
									position: "absolute",
									// top: "-3px",
									// left: "-3px",
									height: "10px",
									width: "10px",
								}}
								src={ERROR}
								alt="err"
							/>
							// <AiOutlineCalendar style={{ color: 'white', fontSize: '20px' }} />
						)}
						{value === "calender" && location.pathname === "/quarters" ? (
							// <img
							//   style={{ height: "20px", width: "16px" }}
							//   src={CALANDER_ACTIVE_IC}
							//   alt="home"
							// />
							<AiOutlineCalendar style={{ color: '#31A7DB', fontSize: '25px', marginTop: '2px' }} />
						) : (
							// <img
							//   style={{ height: "20px", width: "16px" }}
							//   src={CALANDER_IC}
							//   alt="homeic"
							// />
							<AiOutlineCalendar style={{ color: 'white', fontSize: '25px', marginTop: '2px' }} />
						)}
					</div>
				}
			/>
			<BottomNavigationAction style={{ marginBottom: '5px' }}
				value="notifications"
				icon={
					value === "notifications" && location.pathname === "/notifications" ? (
						<div style={{ poition: "relative" }} >
							{
								unreadCount > 0 &&
								<div style={{
									borderRadius: "9999px",
									color: "white",
									backgroundColor: "red",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "absolute",
									right: 0,
									top: 0,
									height: "15px",
									width: "15px",
									// padding: "4px"
									marginTop: "8px",
									marginRight: "30px"
								}}>
									<p style={{ fontSize: "8px" }}>{unreadCount > 9 ? "+9" : unreadCount}</p>
									{/* <p style={{ fontSize: "8px" }}>+9</p> */}
								</div>
							}
							<img
								style={{ height: "20px", width: "16px" }}
								src={BELL_ACTIVE_IC}
								alt="home"
							/>
						</div>
					) : (
						<div style={{ poition: "relative" }} >
							{
								unreadCount > 0 &&
								<div style={{
									borderRadius: "9999px",
									color: "white",
									backgroundColor: "red",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "absolute",
									right: 0,
									top: 0,
									height: "15px",
									width: "15px",
									// padding: "4px"
									marginTop: "8px",
									marginRight: "30px"
								}}>
									<p style={{ fontSize: "8px" }}>{unreadCount > 9 ? "+9" : unreadCount}</p>
									{/* <p style={{ fontSize: "8px" }}>+9</p> */}
								</div>
							}
							<img
								style={{ height: "20px", width: "16px" }}
								src={BELL_IC}
								alt="homeic"
							/>
						</div>
					)
				}
			/>
			<BottomNavigationAction style={{ marginBottom: '6px' }}
				value="menu"
				icon={
					// <PermIdentityIcon
					//   color={value === "menu" ? "primary" : "secondary"}
					// />
					value === "profile" && location.pathname === "/profile" ? (
						<img src={Blue} />
					) : (
						<img src={White} />
					)
				}
			/>
		</BottomNavigation>
	);
}
export default withRouter(BottomNav);

import React, { useState } from "react";
import { withRouter } from "react-router";
import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./subComponents/header";
import UserCard from "./subComponents/userCard";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import TagCard from "./subComponents/tagCard";
import * as BaseActions from "store/actions";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
	tab: {
		minWidth: 50,
		fontSize: 16,
		fontFamily: "cerebri-regular",
		textTransform: "none",
		"&:not(p)": {
			color: "white",
		},
	},
	tabContainer: {
		backgroundColor: "transparent",
		width: "100%",
		color: "white",
	},
}));
const TABS = ['top', 'people', 'tags']
function Search({ history, match }) {
	const dispatch = useDispatch();
	const [filter, setFilter] = React.useState({
		sortRating: 1,
		tab: match.params.tab || TABS[0],
	});
	const [value, setValue] = React.useState(TABS.indexOf(match.params.tab));
	const [page, setPage] = React.useState(1);
	const [hasMore, setHasMore] = React.useState(false);
	const [searchValue, setSearchValue] = React.useState(!!history.location.state ? history.location.state.searchValue : "");
	const [users, setUsers] = React.useState({
		matched: [],
		suggested: [],
		tags: [],
		suggestedTags: [],
	});
	const classes = useStyles();
	// this is how we can select our reducer.
	const fetchedUsers = useSelector(({ Search }) => Search.SearchReducer); //single line
	let isLoading = fetchedUsers.isLoading;
	// if we change tab, text or scroll down for changing page then it will call this method.
	const handleChange = (event, val) => {
		setValue(val);
		setPage(1);
		setUsers({
			matched: [],
			suggested: [],
			tags: [],
			suggestedTags: [],
		});
		setFilter({ ...filter, tab: TABS[val] })
		if (val === 0) {
			history.push('/search/top')
		}
		if (val === 1) {
			history.push('/search/people')
		}
		if (val === 2) {
			history.push('/search/tags')
		}
		if (val === 3) {
			history.push('/search/username')
		}
	};
	const filterHandler = (data) => {
		if (data) {
			const rating = parseFloat(data.rating);
			const minRate = Number(parseFloat(data.minPrice).toFixed(2));
			const maxRate = Number(parseFloat(data.maxPrice).toFixed(2));
			const availableNow = data.availableNow ? 1 : 0;
			const sortReviews = data.descendingReview ? 1 : -1;
			const sortRating = data.descendingRating ? 1 : -1;
			setFilter({
				...filter,
				rating,
				minRate,
				maxRate,
				availableNow,
				sortRating,
				sortReviews,
			});
		} else setFilter({ sortRating: 1, page: 1, tab: "top" });
	};
	const handleSearchChange = (event) => {
		let val = event.target.value;
		setPage(1);
		setUsers({
			matched: [],
			suggested: [],
			tags: [],
			suggestedTags: [],
		});
		setSearchValue(val);
	};
	const observer = React.useRef();
	// onScroll optimized data fetching.
	const lastElementRef = React.useCallback(
		(node) => {
			if (isLoading) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage((prevPageNumber) => prevPageNumber + 1);
				}
			});
			if (node) observer.current.observe(node);
		},
		[isLoading, hasMore]
	);
	// whenever we change our filterState, textValue state and page state this will emmit sideEffect and reCall this hook..
	useDeepCompareEffect(() => {
		console.log("---hello")
		let timeout = setTimeout(() => {
			let newFilter = { ...filter, page, tab: TABS[value] };
			dispatch(Actions.searchProfiles(searchValue, newFilter));
		}, 500);
		return () => {
			clearTimeout(timeout);
		};
	}, [filter, searchValue, page, value]);
	// handling returned data from api.
	useDeepCompareEffect(() => {
		if (fetchedUsers.data && fetchedUsers.data.data) {
			filter.tab === "tags"
				? setUsers({
					matched: [],
					suggested: [],
					suggestedTags: fetchedUsers.data.data.suggested,
					// tags: fetchedUsers.data.data.tags,
					tags: fetchedUsers.data.data.tags
						? [...new Set([...users.tags, ...fetchedUsers.data.data.tags])]
						: [...new Set([...users.tags, ...[]])],
					// matched: [],
				})
				: setUsers({
					matched: fetchedUsers.data.data.users
						? [
							...new Set([
								//...users.matched, //Just use the server returned results.
								...fetchedUsers.data.data.users,
							]),
						]
						: [...new Set([...users.matched, ...[]])],
					// matched: fetchedUsers.data.data.users,
					suggested: fetchedUsers.data.data.suggested,
					tags: [],
					suggestedTags: [],
				});
			// if data has more page then it will conduct onScroll call
			fetchedUsers.data.data.totalPages > page
				? setHasMore(true)
				: setHasMore(false);
			return;
		}
	}, [fetchedUsers]);
	React.useEffect(() => {
		setUsers({
			matched: [],
			suggested: [],
			tags: [],
		});
		dispatch(BaseActions.updateLayout({ bottomNav: true }));
		return () => {
			dispatch(BaseActions.updateLayout({ bottomNav: true }));
		};
	}, []);
	const handleNavigate = (path, data) => {
		history.push({ pathname: path, state: { ...data, tab: match.params.tab, searchValue: searchValue } });
	};
	return (
		<Box
			display="flex"
			flexDirection="column"
			position="absolute"
			width="100%"
			height="100%"
			zIndex={50}
			marginTop="-11px"
		>
			<Box>
				<Header
					searchBar
					value={searchValue}
					handleChange={handleSearchChange}
					filterHandler={filterHandler}
					appliedFilter={filter}
				/>
				<Box padding="10px 20px 0px 20px">
					<Paper className={classes.tabContainer}>
						<Tabs
							variant="fullWidth"
							value={value}
							indicatorColor="primary"
							textColor="primary"
							onChange={handleChange}
						>
							<Tab label="Top" className={classes.tab} />
							<Tab label="People" className={classes.tab} />
							<Tab label="Expertise" className={classes.tab} />
						</Tabs>
					</Paper>
				</Box>
			</Box>
			<Box
				style={{
					overflowY: fetchedUsers.isLoading ? "hidden" : "auto",
					flexGrow: 1,
					marginBottom: 50,
				}}
				id="asd"
			// onScroll={handleScroll}
			>
				{value === 0 && (
					<>
						{searchValue.length === 0 &&
							users.suggested &&
							users.suggested.length !== 0 && (
								<>
									<Box padding={isMobile ? "15px 30px 10px" : "40px 30px 10px"}>
										<Typography variant="subtitle2">Suggested</Typography>
									</Box>
									{users.suggested &&
										users.suggested.map((u, i) => {
											if (u.rate > 0) {
												return <div onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}>
													<UserCard key={i} id={i} user={u} />
												</div>
											}
										})
									}
								</>
							)}
						{searchValue.length === 0 && users.matched.length !== 0 && (
							<Box padding="10px 30px 10px">
								<Typography variant="subtitle2">Recent</Typography>
							</Box>
						)}
						{users.matched &&
							users.matched.map((u, i) => {
								if (u.rate > 0) {
									return users.matched.length === i + 1 ? (
										<div
											ref={lastElementRef}
											onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}
										>
											<UserCard key={i} id={i} user={u} />
										</div>
									) : (
										<div onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}>
											<UserCard key={i} id={i} user={u} />
										</div>
									)
								}
							}
							)}
					</>
				)}
				{value === 1 && (
					<>
						{searchValue.length === 0 &&
							users.suggested &&
							users.suggested.length !== 0 && (
								<>
									<Box padding={isMobile ? "15px 30px 10px" : "40px 30px 10px"}>
										<Typography variant="subtitle2">Suggested</Typography>
									</Box>
									{users.suggested &&
										users.suggested.map((u, i) => {
											if (u.rate > 0) {
												return <div onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}>
													<UserCard key={i} id={i} user={u} />
												</div>
											}
										})
									}
								</>
							)}
						{searchValue.length === 0 && users.matched.length !== 0 && (
							<Box padding="10px 30px 10px">
								<Typography variant="subtitle2">Recent</Typography>
							</Box>
						)}
						{users.matched &&
							users.matched.map((u, i) => {
								if (u.rate > 0) {
									return users.matched.length === i + 1 ? (
										<div
											ref={lastElementRef}
											onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}
										>
											<UserCard key={i} id={i} user={u} />
										</div>
									) : (
										<div onClick={() => handleNavigate(`/userprofile/${u.user_code}?from=search`, u)}>
											<UserCard key={i} id={i} user={u} />
										</div>
									)
								}
							}
							)}
					</>
				)}
				{value === 2 && (
					<>
						{searchValue.length === 0 &&
							users.suggestedTags &&
							users.suggestedTags.length !== 0 && (
								<>
									<Box padding={isMobile ? "15px 30px 10px" : "40px 30px 10px"}>
										<Typography variant="subtitle2">Suggested</Typography>
									</Box>
									{users.suggestedTags &&
										users.suggestedTags.map((tag, i) => (
											<TagCard id={tag} tag={tag} />
										))}
								</>
							)}
						{searchValue.length === 0 && !users.tags && (
							<Box padding="10px 30px 10px">
								<Typography variant="subtitle2">Recent</Typography>
							</Box>
						)}
						{users.tags &&
							users.tags.map((tag, i) =>
								users.tags.length === i + 1 ? (
									<TagCard ref={lastElementRef} id={tag} tag={tag} />
								) : (
									<TagCard id={tag} tag={tag} />
								)
							)}
					</>
				)}
			</Box>
		</Box >
	);
}
// we need to export our component by using withReducer to connecting store.
export default withReducer("Search", reducer)(withRouter(Search));

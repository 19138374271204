import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import InputGroup from "./subComponents/inputGroup";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "@base";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import { isIOS, isMobile } from "react-device-detect";
import queryString from 'query-string'
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from 'clsx'
import { initFirebase } from "@utils";
const useStyles = makeStyles((theme) => ({
	container: {
		// marginTop: "auto",
		marginBottom: "20px",
		// paddingBottom: "80px",
		[theme.breakpoints.down('sm')]: {
			paddingBottom: isMobile && isIOS ? "100px" : "20px",
			// marginBottom: isMobile && isIOS ? "52px" : "52px",
		},
		[theme.breakpoints.between('md', 'xl')]: {
			marginBottom: "-8px",
		},
		height: isMobile ? "10vh" : "auto",
		paddingTop: "15px",
		paddingBottom: "15px",
		backgroundColor: "#080A0A"
	},
	overflowY: {
		overflowY: "scroll"
	},
	errorText: {
		position: 'absolute',
		fontFamily: "cerebri-regular",
		color: 'rgb(203, 58, 58)',
		fontSize: '14px',
	},
	errorText1: {
		marginTop: '-20px',
		marginLeft: '39px'
	},
	errorText2: {
		marginTop: '-20px',
		marginRight: '50px',
		right: '0',
	}
}));
const Expertise = (props) => {
	const dispatch = useDispatch();
	const updateProfileResponse = useSelector(
		({ Expertise }) => Expertise.UpdateProfileReducer
	);
	const updateProfileResponseData = updateProfileResponse.data;
	const isLoading = updateProfileResponse.isLoading;
	const [fromExpertise, setFromExpertise] = React.useState(false);
	const id = queryString.parse(props.location.search).id
	const { layout } = useSelector(({ BaseReducer }) => ({
		layout: BaseReducer.LayoutReducer,
	}));
	const [initialScreenSize, setInitialScreenSize] = React.useState(window.innerHeight);
	const [keyboardIsShown, setKeyboardIsShown] = React.useState(false);
	React.useEffect(() => {
		setInterval(() => {
			if (initialScreenSize > window.innerHeight) {
				!keyboardIsShown && setKeyboardIsShown(true);
			} else {
				keyboardIsShown && setKeyboardIsShown(false)
			}
		}, 1)
	}, [keyboardIsShown, initialScreenSize])
	React.useEffect(() => {
		if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [layout.bottomNav])
	React.useEffect(() => {
		if (updateProfileResponseData && fromExpertise) {
			if (updateProfileResponseData.status === "Success") {
				// route here
				if (props.tags) {
					props.handleBackToEdit();
					return;
				} else if (id) {
					props.history.push(`/userprofile/${id}`);
				}
				else {
					props.history.push("/home");
				}
			}
		}
	}, [updateProfileResponseData]);
	const submitExpertise = (valuesObj) => {
		initFirebase(dispatch);
		setFromExpertise(true)
		let bluePrint = ["expertise", "years"];
		let arr = [];
		for (let i = 1; i <= 5; i++) {
			let a = valuesObj[`${bluePrint[0]}${i}`];
			let b = valuesObj[`${bluePrint[1]}${i}`];
			if (a) {
				arr.push({ expertise: a, years: b });
			}
		}
		let expOfArray = [];
		arr.forEach((obj) => {
			expOfArray.push(obj["expertise"]);
		});
		let filteredArray = [...new Set(expOfArray)];
		if (filteredArray.length === arr.length) {
			if (props.tags) {
				props.handleTagsChange(arr)
				props.backToEdit()
			} else {
				dispatch(Actions.updateProfile({ tags: arr }));
			}
		}
	};
	const classes = useStyles();
	return (
		<Formik
			initialValues={{
				expertise1:
					props.tags && props.tags[0] ? props.tags[0].expertise.trim() : "",
				years1: props.tags && props.tags[0] ? Number(props.tags[0].years) : 1,
				expertise2:
					props.tags && props.tags[1] ? props.tags[1].expertise.trim() : "",
				years2: props.tags && props.tags[1] ? Number(props.tags[1].years) : 1,
				expertise3:
					props.tags && props.tags[2] ? props.tags[2].expertise.trim() : "",
				years3: props.tags && props.tags[2] ? Number(props.tags[2].years) : 1,
				expertise4:
					props.tags && props.tags[3] ? props.tags[3].expertise.trim() : "",
				years4: props.tags && props.tags[3] ? Number(props.tags[3].years) : 1,
				expertise5:
					props.tags && props.tags[4] ? props.tags[4].expertise.trim() : "",
				years5: props.tags && props.tags[4] ? Number(props.tags[4].years) : 1,
			}}
			onSubmit={(values) => {
				console.table(values);
				submitExpertise(values);
			}}
			validationSchema={Yup.object().shape({
				expertise1: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				// .required("*Required"),
				years1: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				// .required("*Required"),
				// .required("*Required")
				expertise2: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				// .required("*Required")
				years2: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				// .required("*Required")
				expertise3: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				// .required("*Required")
				years3: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				// .required("*Required*")
				expertise4: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				// .required("*Required")
				years4: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				// .required("*Required")
				expertise5: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				// .required("*Required")
				years5: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				// .required("*Required")
			})}
		>
			{(formikProps) => {
				const {
					values,
					touched,
					errors,
					dirty,
					isValid,
					handleChange,
					setFieldValue,
					handleBlur,
					handleSubmit,
				} = formikProps;
				const expertval1 = values.expertise1;
				const expertval2 = values.expertise2;
				const expertval3 = values.expertise3;
				const expertval4 = values.expertise4;
				const expertval5 = values.expertise5;
				const expertlen1 = values.expertise1.length;
				const expertlen2 = values.expertise2.length;
				const expertlen3 = values.expertise3.length;
				const expertlen4 = values.expertise4.length;
				const expertlen5 = values.expertise5.length;
				return (
					<form onSubmit={handleSubmit} style={{ height: "100%" }}>
						<Box
							display="flex"
							flexDirection="column"
							height="100%"
						>
							<Box padding={isMobile ? "15px 28px 0 28px" : "30px 28px 0 28px"}>
								<Typography style={{ lineHeight: "40px" }} variant="h1">
									What Are Your Areas <br />
									Of Expertise?
								</Typography>
								<Box
									display="flex"
									justifyContent="space-between"
									padding="0px 25px 0 30px"
									alignItems="flex-end"
									marginTop="20px"
								>
									<Typography variant="subtitle2">Expertise</Typography>
									<Typography variant="subtitle2">Years exp</Typography>
								</Box>
							</Box>
							<Box padding={isMobile ? "15px 28px 0 28px" : "30px 28px 0 28px"} className={classes.overflowY} height={isMobile ? "60vh" : "auto"} paddingBottom="30px">
								<InputGroup
									name="expertise1"
									number="1st"
									label=""
									expertiseProps={{
										// error:
										// 	values.expertise1 &&
										// 		errors.expertise1 &&
										// 		touched.expertise1
										// 		? errors.expertise1
										// 		: false,
										value: values.expertise1,
										setFieldValue,
										handleBlur,
										name: "expertise1",
										label: "",
									}}
									yearsProps={{
										// error: errors.years1 ? errors.years1 : false,
										value: values.years1,
										setFieldValue,
										handleBlur,
										name: "years1",
										label: "",
									}}
								// isValid={!errors.email && touched.email}
								/>
								{values.expertise1.length < 2 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise1.length > 1 || values.expertise1.length === 0 ? "none" : null }}>*Must be at least 2 characters</span> : null}
								{values.expertise1.length > 45 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise1.length < 46 ? "none" : null }}>*Must be less than 45 characters</span> : null}
								{values.years1 > 45 ? <span className={clsx(classes.errorText, classes.errorText2)} style={{ display: values.years1 < 46 ? "none" : null }}>*1 - 45</span> : null}
								{values.expertise1 === values.expertise2 || values.expertise1 === values.expertise3 || values.expertise1 === values.expertise4 || values.expertise1 === values.expertise5 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise1.length < 2 || values.expertise1.length > 45 ? "none" : null }}>*Duplicate entry</span> : null}
								<InputGroup
									name="expertise2"
									number="2nd"
									label=""
									expertiseProps={{
										// error:
										// 	errors.expertise2 && touched.expertise2
										// 		? errors.expertise2
										// 		: false,
										value: values.expertise2,
										setFieldValue,
										handleBlur,
										name: "expertise2",
										label: "",
										// disabled:
										//   values.expertise1.length === 0 || errors.expertise1,
									}}
									yearsProps={{
										// error: errors.years2 ? errors.years2 : false,
										value: values.years2,
										setFieldValue,
										handleBlur,
										name: "years2",
										label: "",
										// disabled:
										//   values.expertise1.length === 0 || errors.expertise1,
									}}
								// isValid={!errors.email && touched.email}
								/>
								{values.expertise2.length < 2 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise2.length > 1 || values.expertise2.length === 0 ? "none" : null }}>*Must be at least 2 characters</span> : null}
								{values.expertise2.length > 45 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise2.length < 46 ? "none" : null }}>*Must be less than 45 characters</span> : null}
								{values.years2 > 45 ? <span className={clsx(classes.errorText, classes.errorText2)} style={{ display: values.years2 < 46 ? "none" : null }}>*1 - 45</span> : null}
								{values.expertise2 === values.expertise1 || values.expertise2 === values.expertise3 || values.expertise2 === values.expertise4 || values.expertise2 === values.expertise5 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise2.length < 2 || values.expertise2.length > 45 ? "none" : null }}>*Duplicate entry</span> : null}
								<InputGroup
									number="3rd"
									name="expertise3"
									label=""
									expertiseProps={{
										// error:
										// 	errors.expertise3 && touched.expertise3
										// 		? errors.expertise3
										// 		: false,
										value: values.expertise3,
										setFieldValue,
										handleBlur,
										name: "expertise3",
										label: "",
										// disabled:
										//   values.expertise2.length === 0 || errors.expertise2,
									}}
									yearsProps={{
										// error: errors.years3 ? errors.years3 : false,
										value: values.years3,
										setFieldValue,
										handleBlur,
										name: "years3",
										label: "",
										// disabled:
										//   values.expertise2.length === 0 || errors.expertise2,
									}}
								// isValid={!errors.email && touched.email}
								/>
								{values.expertise3.length < 2 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise3.length > 1 || values.expertise3.length === 0 ? "none" : null }}>*Must be at least 2 characters</span> : null}
								{values.expertise3.length > 45 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise3.length < 46 ? "none" : null }}>*Must be less than 45 characters</span> : null}
								{values.years3 > 45 ? <span className={clsx(classes.errorText, classes.errorText2)} style={{ display: values.years3 < 46 ? "none" : null }}>*1 - 45</span> : null}
								{values.expertise3 === values.expertise1 || values.expertise3 === values.expertise2 || values.expertise3 === values.expertise4 || values.expertise3 === values.expertise5 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise3.length < 2 || values.expertise3.length > 45 ? "none" : null }}>*Duplicate entry</span> : null}
								<InputGroup
									number="4th"
									name="expertise4"
									label=""
									expertiseProps={{
										// error: errors.expertise4 ? errors.expertise4 : false,
										value: values.expertise4,
										setFieldValue,
										handleBlur,
										name: "expertise4",
										label: "",
										// disabled:
										//   values.expertise3.length === 0 || errors.expertise3,
									}}
									yearsProps={{
										// error: errors.years4 ? errors.years4 : false,
										value: values.years4,
										setFieldValue,
										handleBlur,
										name: "years4",
										label: "",
										// disabled:
										//   values.expertise3.length === 0 || errors.expertise3,
									}}
								// isValid={!errors.email && touched.email}
								/>
								{values.expertise4.length < 2 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise4.length > 1 || values.expertise4.length === 0 ? "none" : null }}>*Must be at least 2 characters</span> : null}
								{values.expertise4.length > 45 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise4.length < 46 ? "none" : null }}>*Must be less than 45 characters</span> : null}
								{values.years4 > 45 ? <span className={clsx(classes.errorText, classes.errorText2)} style={{ display: values.years4 < 46 ? "none" : null }}>*1 - 45</span> : null}
								{values.expertise4 === values.expertise1 || values.expertise4 === values.expertise2 || values.expertise4 === values.expertise3 || values.expertise4 === values.expertise5 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise4.length < 2 || values.expertise4.length > 45 ? "none" : null }}>*Duplicate entry</span> : null}
								<InputGroup
									number="5th"
									name="expertise5"
									label=""
									expertiseProps={{
										// error:
										// 	errors.expertise5 && touched.expertise5
										// 		? errors.expertise5
										// 		: false,
										value: values.expertise5,
										setFieldValue,
										handleBlur,
										name: "expertise5",
										label: "",
										// disabled:
										//   values.expertise4.length === 0 || errors.expertise3,
									}}
									yearsProps={{
										// error:
										// 	errors.years5 && touched.years5 ? errors.years5 : false,
										value: values.years5,
										setFieldValue,
										handleBlur,
										name: "years5",
										label: "",
										// disabled:
										//   values.expertise4.length === 0 || errors.expertise3,
									}}
								// isValid={!errors.email && touched.email}
								/>
								{values.expertise5.length < 2 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise5.length > 1 || values.expertise5.length === 0 ? "none" : null }}>*Must be at least 2 characters</span> : null}
								{values.expertise5.length > 45 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise5.length < 46 ? "none" : null }}>*Must be less than 45 characters</span> : null}
								{values.years5 > 45 ? <span className={clsx(classes.errorText, classes.errorText2)} style={{ display: values.years5 < 46 ? "none" : null }}>*1 - 45</span> : null}
								{values.expertise5 === values.expertise1 || values.expertise5 === values.expertise2 || values.expertise5 === values.expertise3 || values.expertise5 === values.expertise4 ? <span className={clsx(classes.errorText, classes.errorText1)} style={{ display: values.expertise5.length < 2 || values.expertise5.length > 45 ? "none" : null }}>*Duplicate entry</span> : null}
							</Box>
							<Box hidden={(isMobile && keyboardIsShown) ? true : false} position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 0}
								padding="auto 20px 10px 20px"
								padding="30px"
							// paddingBottom={"20px"}
							>
								{props.tags ? (
									<Box display="flex">
										<Button
											// type="submit"
											fullWidth
											style={{ margin: "0px 10px" }}
											variant="outlined"
											color="secondary"
											onClick={props.backToEdit}
										>
											Back
										</Button>
										<Button
											type="submit"
											fullWidth
											style={{ margin: "0px 10px" }}
											disabled={
												!dirty ||
												!isValid ||
												isLoading ||
												values.expertise1.length < 2 &&
												values.expertise2.length < 2 &&
												values.expertise3.length < 2 &&
												values.expertise4.length < 2 &&
												values.expertise5.length < 2 ||
												expertlen1 > 2 && expertval1 === expertval2 || expertlen1 > 2 && expertval1 === expertval3 || expertlen1 > 2 && expertval1 === expertval4 || expertlen1 > 2 && expertval1 === expertval5 ||
												expertlen2 > 2 && expertval2 === expertval1 || expertlen2 > 2 && expertval2 === expertval3 || expertlen2 > 2 && expertval2 === expertval4 || expertlen2 > 2 && expertval2 === expertval5 ||
												expertlen3 > 2 && expertval3 === expertval1 || expertlen3 > 2 && expertval3 === expertval2 || expertlen3 > 2 && expertval3 === expertval4 || expertlen3 > 2 && expertval3 === expertval5 ||
												expertlen4 > 2 && expertval4 === expertval1 || expertlen4 > 2 && expertval4 === expertval2 || expertlen4 > 2 && expertval4 === expertval3 || expertlen4 > 2 && expertval4 === expertval5 ||
												expertlen5 > 2 && expertval5 === expertval1 || expertlen5 > 2 && expertval5 === expertval2 || expertlen5 > 2 && expertval5 === expertval3 || expertlen5 > 2 && expertval5 === expertval4
											}
										>
											{isLoading ? <Spinner /> : "Done"}
										</Button>
									</Box>
								) : (
									<Button
										type="submit"
										fullWidth
										// disabled={(values.expertise1 === "" && errors.expertise1) || (values.expertise2 === "" && errors.expertise2)}
										disabled={
											!dirty ||
											!isValid ||
											isLoading ||
											values.expertise1.length < 2 &&
											values.expertise2.length < 2 &&
											values.expertise3.length < 2 &&
											values.expertise4.length < 2 &&
											values.expertise5.length < 2 ||
											expertlen1 > 2 && expertval1 === expertval2 || expertlen1 > 2 && expertval1 === expertval3 || expertlen1 > 2 && expertval1 === expertval4 || expertlen1 > 2 && expertval1 === expertval5 ||
											expertlen2 > 2 && expertval2 === expertval1 || expertlen2 > 2 && expertval2 === expertval3 || expertlen2 > 2 && expertval2 === expertval4 || expertlen2 > 2 && expertval2 === expertval5 ||
											expertlen3 > 2 && expertval3 === expertval1 || expertlen3 > 2 && expertval3 === expertval2 || expertlen3 > 2 && expertval3 === expertval4 || expertlen3 > 2 && expertval3 === expertval5 ||
											expertlen4 > 2 && expertval4 === expertval1 || expertlen4 > 2 && expertval4 === expertval2 || expertlen4 > 2 && expertval4 === expertval3 || expertlen4 > 2 && expertval4 === expertval5 ||
											expertlen5 > 2 && expertval5 === expertval1 || expertlen5 > 2 && expertval5 === expertval2 || expertlen5 > 2 && expertval5 === expertval3 || expertlen5 > 2 && expertval5 === expertval4
										}
									>
										{isLoading ? <Spinner /> : "Done"}
									</Button>
								)}
							</Box>
						</Box>
					</form>
				);
			}}
		</Formik >
	);
};
export default withReducer("Expertise", reducer)(withRouter(Expertise));
import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./store/actions";
import * as BaseActions from 'store/actions'
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
function getLocaleDate(iso) {
  var date = new Date(iso);
  var options = { month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options)
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  modalContainer: {
    outline: "none",
    position: "fixed",
    bottom: 0,
    marginBottom: "33px",
    width: "370px",
    padding: "10px",
    borderRadius: 0,
    color: "#FFFFFF",
    backgroundColor: "#111111",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalActionContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  reviewContainer: {
    display: "flex",
    // alignItems:"center",
    // justifyContent:"space-between",
    flexDirection: 'column'
  },
  container: {
    // padding: "30px 30px 30px 30px",
    // height: "80vh",
    [theme.breakpoints.down("sm")]: {
      // padding: isAndroid && "100px 30px 30px 30px",
      // padding: "200px 30px 30px 30px"
    }
  },
  closeIcon: {
    stroke: "#CB3A3A",
    strokeWidth: 2,
    position: "absolute",
    right: 15,
    top: 15,
    color: "#CB3A3A",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      // top: isAndroid && 100,
      // top: 200
    }
  }
}));
const Reviews = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fetchedReviews, setReviews] = React.useState([]);
  const fetchedData = useSelector(({ Reviews }) => Reviews.FetchReviewsReducer);
  useDeepCompareEffect(() => {
    if (fetchedData.data) {
      setReviews(fetchedData.data.data);
    }
  }, [fetchedData]);
  React.useEffect(() => {
    // alert(props.userId);
    dispatch(BaseActions.updateLayout({ bottomNav: false }))
    dispatch(Actions.fetchReviews(props.userId));
    return () => {
      dispatch(BaseActions.updateLayout({ bottomNav: true }))
      dispatch(Actions.resetReviews())
    }
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <Box padding="30px" position="relative" minHeight="180px">
      <Box display="flex" flexDirection="column">
        <CloseIcon
          className={classes.closeIcon}
          // onClick={handleClickOpen}
          onClick={handleClose}
        />
        {/* <Modal
          open={open}
          onClose={handleModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.modalContainer}>
            <Box>
              <Typography variant="caption">
                Are you sure you want to close?
              </Typography>
              <Box marginTop="auto" className={classes.modalActionContainer}>
                <Button
                  onClick={handleModalClose}
                  variant="outlined"
                  color="default"
                  style={{ width: "45%" }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  onClick={() => handleClose()}
                  color="primary"
                  style={{ width: "45%" }}
                >
                  {"Yes"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal> */}
        {
          fetchedReviews.length === 0 ? null :
            (<Typography variant="body2">{`Reviews(${props.count || 0
              })`}</Typography>)
        }
      </Box>
      {/* {fetchedReviews.map(review=>{
})} */}
      {fetchedReviews.length === 0 ? (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <Typography variant="caption">You have no reviews!</Typography>
        </div>
      ) : (
        fetchedReviews.map((item) => {
          console.log({ attendee: item.attendee })
          const userName = item.attendee ? item.attendee.enabledUsername ? item.attendee.username : item.attendee.full_name : 'Quartrly User'
          return < ReviewItem
            name={userName}
            comment={item.comment}
            rating={item.averageRating}
            subject={item.subject}
            date={item.createdAt}
          />
        })
      )}
    </Box>
  );
};
export default withReducer("Reviews", reducer)(Reviews);
const ReviewItem = ({ name, subject, rating, comment, date }) => {
  const classes = useStyles();
  return (
    <Box margin={isMobile ? "20px 0px" : "40px 0px"} style={{ wordBreak: 'break-word' }}>
      <Box className={classes.reviewContainer}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography
            variant="body2"
            style={{ color: "#fff", fontSize: "16px" }}
          >
            {name}
          </Typography>
          <Rating
            name="read-only"
            precision={0.1}
            value={rating || 0}
            readOnly
            size="small"
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {subject && (
            <div
              style={{
                margin: "5px 5px 5px 0px",
                padding: "6px 14px",
                borderRadius: 6,
                border: "1px solid #808080",
                textAlign: "center",
              }}
            >
              <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                {subject || ""}
              </Typography>
            </div>
          )}
        </div>
      </Box>
      <Box marginTop="10px">
        <Typography variant="caption">{comment || ""}</Typography>
      </Box>
      <Box marginTop="8px">
        <Typography style={{ fontSize: "12px" }} variant="subtitle2">
          {date
            ? `${getLocaleDate(date)} at ${new Date(date).toLocaleTimeString(navigator.language, {
              hour: '2-digit',
              minute: '2-digit'
            })}`
            : ""}
          {/* Aug, 8 at 4:25AM */}
        </Typography>
      </Box>
    </Box>
  );
};

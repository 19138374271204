import React from "react";

import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "28px",
    borderBottom: "2px dashed #31A7DB",
    borderSpacing: 2,
    color: "white",
    marginRight: 10,
    textAlign: "center",
    paddingTop: "13px",
    height: "16px",
    borderRadius: 0
  },
  root: {
    fontFamily: "cerebri-regular",
    fontSize: "24px",
    "& > *": { color: "white" },
    "&:before": {
      borderBottom: "0 !important",
    },
    "&:after": {
      borderBottom: "0 !important",
    },
  },
}));

const ReferalInput = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Input
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      inputRef={ref}
      {...props}
      //   value={props.value}
      classes={{
        root: classes.root,
        input: classes.inputField,
        width: "28px",
      }}
      inputProps={{
        maxLength: 1,
      }}
    />
  );
});

export default React.memo(ReferalInput);

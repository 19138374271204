import React, { memo } from "react";
import {
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import * as BaseActions from 'store/actions';
import DrawerMenu from 'components/drawerMenu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 19,
    padding: 17,
    fontWeight: 'bold',
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      padding: "15px 17px 17px 17px"
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  menuIcon: {
    width: 18,
    cursor: 'pointer',
    marginRight: '1rem',
  },
}))
const Header = ({ heading, RightComponent, backArrow }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.leftSideMenu}>
        {backArrow ?
          <ArrowBackIosIcon color='secondary' onClick={history.goBack} />
          :
          <img src='assets/Tabbar/TabbarInactive/menu.png' className={classes.menuIcon} onClick={() => dispatch(BaseActions.showMessage(<DrawerMenu />))} />
        }
        <Typography variant='h5' className={classes.headerText}>{heading}</Typography>
      </div>
      {!!RightComponent &&
        <div>
          <RightComponent />
        </div>
      }
    </div>
  );
};
export default memo(Header)

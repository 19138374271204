import React from "react";
import { withRouter } from "react-router";
import {
  Box, Button, Typography,
  // withStyles, 
  makeStyles
} from "@material-ui/core";
import useDeepCompareEffect from "use-deep-compare-effect";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import * as BaseActions from "store/actions";
import * as Actions from 'components/signup/screens/signupForm/store/actions'
import reducers from "components/signup/screens/signupForm/store/reducers";
import "react-multi-carousel/lib/styles.css";
import ManageQuarters from './ManageQuarters'
import { Link } from 'react-router-dom';
import DrawerMenu from 'components/drawerMenu';
import useDetectZoomLevel from "hooks/useDetectZoomLevel";
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 3,
    padding: 17,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: "15px 17px 0px 17px",
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  menuIcon: {
    width: "20px",
    cursor: 'pointer',
    marginRight: '1rem',
    height: "20px"
  },
  historyIcon: {
    color: '#fff',
    width: 18,
    cursor: 'pointer',
  },
  availabilityBtn: {
    width: '82%',
    left: '2rem',
    // position: 'absolute',
    bottom: 100,
    [theme.breakpoints.down('xs')]: {
      left: '2.7rem',
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: 'center',
    backgroundColor: "#080A0A",
    zIndex: "100",
    position: "fixed",
    width: "100%",
    height: "70px",
  
    [theme.breakpoints.down('xl')]: {
      position: "relative",
      bottom: "20px !important",
      height: "70px",
      marginTop:"-25px !important",
    },
    [theme.breakpoints.down('lg')]: {
      position: "relative",
      bottom: "0px",
      height: "70px",
      marginTop:"-15px !important"
    },
    [theme.breakpoints.down('md')]: {
      position: "relative",
      bottom: "20px !important",
      height: "70px",
      marginTop:"-15px !important",
    },
    [theme.breakpoints.down('sm')]: {
      position: "relative",
      bottom: "20px",
      height: "70px",
      marginTop:"15px !important",
    },
    [theme.breakpoints.down('xs')]: {
      position: "relative",
      bottom: "10px !important",
      height: "70px",
      marginTop:"15px !important",
    },
  },
  bottomBtn: {
    width: "82%",
  }
}))
function Quarters({ history }) {
  const dispatch = useDispatch();
  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
  const classes = useStyles();
  const zoomLevel = useDetectZoomLevel()
  const updateProfile = useSelector(
    ({ Availability }) => Availability.UpdateProfileReducer
  );
  useDeepCompareEffect(() => {
    if (updateProfile.data) {
      dispatch(Actions.resetProfileReducer());
    }
  }, [updateProfile]);
  const handleNavigate = () => {
    history.push("/availability");
  };
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
    let timeout = setTimeout(() => {
      dispatch(Actions.updateProfile({}))
    }, 100);
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <div>
      <div className={classes.headerWrapper}>
        <div className={classes.leftSideMenu}>
          <img src='assets/Tabbar/TabbarInactive/menu.png' className={classes.menuIcon} onClick={() => dispatch(BaseActions.showMessage(<DrawerMenu />))} />
          <Typography variant='h5' className={classes.headerText}>Quartrs</Typography>
        </div>
        <Link to='/history'>
          <img src='assets/icons/go-to-history.png' className={classes.menuIcon} style={{ margin: 0 }} />
        </Link>
      </div>
      <>
        <Box>
          <ManageQuarters />
          <Box className={classes.btnContainer}>
            <Button onClick={() => handleNavigate()} className={classes.bottomBtn}>
              Set My Availability
            </Button>
          </Box>
        </Box>
      </>
    </div>
  );
}
export default withReducer("Availability", reducers)(withRouter(Quarters));

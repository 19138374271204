import React from "react";
import Header from "components/home/subComponents/header";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import UserCard from "components/home/subComponents/userCard";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as Actions from "components/home/store/actions";
import reducer from "components/home/store/reducers";
import withReducer from "store/withReducer";
import useDeepCompareEffect from "use-deep-compare-effect";
const SECTION_TITLES = {
  recently_viewed: "Recently viewed",
  suggested: "Suggestions",
  new_users: "Newly registered profiles",
  available_now: "Available now",
}
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px",
  },
  cardContainer: {
    margin: "5px",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    "&:after": {
      content: "''",
      // flex:'space-around',
      // maxWidth:'345px',
      maxWidth: "355px",
      // width:'100%',
      // minWidth: '150px',
      minWidth: "175px",
    },
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 70,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  title: { margin: "20px" },
}));
function SeeAll({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const { param } = useParams()
  const title = SECTION_TITLES[param]
  console.log({ title })
  const fetchedData = useSelector(({ SeeAll }) => SeeAll.GetAllReducer);
  const isLoading = fetchedData.isLoading;
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
    // dispatch(Actions.getAll());
  }, []);
  React.useEffect(() => {
    dispatch(Actions.getAll(page, param));
  }, [page, dispatch, param]);
  useDeepCompareEffect(() => {
    // alert(JSON.stringify(fetchedData));
    if (fetchedData.data && fetchedData.data.data) {
      setData([...new Set([...data, ...fetchedData.data.data.users])]);
      fetchedData.data.data.totalPages > page
        ? setHasMore(true)
        : setHasMore(false);
      return;
    }
  }, [fetchedData]);
  const handleNavigate = (path, data) => {
    history.push({ pathname: path, state: data });
  };
  const observer = React.useRef();
  const lastElementRef = React.useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  return (
    <>
      <div style={{}}>
        <Header seeAll />
        <Typography variant="body2" className={classes.title}>
          {title || "Something to show"}
        </Typography>
        <Box className={classes.cardContainer}>
          {data?.map((d, i) => {
            if (d.rate > 0) {
              return data.length === i + 1 ? (
                <div
                  ref={lastElementRef}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate(`/userprofile/${d.user_code}`, d)}
                >
                  <UserCard user={d} key={i} />
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate(`/userprofile/${d.user_code}`, d)}
                >
                  <UserCard user={d} key={i} />
                </div>
              )
            }
          }
          )}
        </Box>
      </div>
    </>
  );
}
export default withReducer("SeeAll", reducer)(withRouter(SeeAll));

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import reducers from "./store/reducers";
import withReducer from "store/withReducer";
import Pics from "@assets";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions";
import * as BaseActions from "./store/actions";
import CheckoutForm from "./subComponents/stripeFields";
import CheckIcon from "@material-ui/icons/Check";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { isMobile } from "react-device-detect";
const stripePromise = loadStripe("pk_live_MIWdkks8jwJHca27NCqcIrFM");
const useStyles = makeStyles((theme) => ({
	container: {
		margin: "30px 20px 0 30px",
		display: "flex",
		flexDirection: "column",
		height: `90%`,
		position: "absolute",
		overflowY: "scroll",
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			height: `${height}px`,
			margin: "30px 20px 0 20px",
		},
		[theme.breakpoints.down("sm")]: {
			margin: "15px 20px 0 30px",
		}
	},
	bottomBtn: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		margin: "auto 0 0 0",
	},
	header: {
		display: "flex",
		alignItems: "center",
	},
	stripeContainer: {
		marginTop: 20,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {

		}
	},
	backButton: {
		color: "white",
		cursor: "pointer",
		marginRight: 5,
	},
	inline: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: "30px",
	},
	inactiveAvailable: {
		marginRight: 20,
		border: "2px solid #808080",
		borderRadius: 6,
		color: "transparent",
		cursor: "pointer",
	},
	activeAvailable: {
		marginRight: 20,
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		color: "white",
		cursor: "pointer",
	},
}));
function viewportToPixels(value) {
	var parts = value.match(/([0-9\.]+)(vh|vw)/)
	var q = Number(parts[1])
	var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
	return side * (q / 100)
}
const height = viewportToPixels("100vh") - 110
function AddPayment({ history }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [callMethod, setCallMethod] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [state, setState] = useState({});
	const [default_method, setDefault] = useState(false);
	const fetchedData = useSelector(
		({ AddPayment }) => AddPayment.AddPaymentReducer
	);
	const enabled = history.location.state;
	useEffect(() => {
		if (state.token) {
			handleSave();
		}
	}, [state]);
	useEffect(() => {
		if (fetchedData?.data && fetchedData.data.status === "Success") {
			history.push("/payment");
			return;
		} else if (fetchedData?.data && fetchedData.data.status === "Fail") {
			setShowError(true)
			setErrorMessage(fetchedData.data.message)
		}
	}, [fetchedData]);
	const handleSave = () => {
		dispatch(
			BaseActions.addPayment({
				...state,
				saved: true,
				default_method,
			})
		);
	};
	React.useEffect(() => {
		dispatch(Actions.updateLayout({ bottomNav: false }));
	}, []);
	return (
		<div>
			<Box className={classes.container}>
				<Box className={classes.header}>
					<ArrowBackIosIcon
						className={classes.backButton}
						onClick={() => history.goBack()}
					/>
					<Typography variant="h1" className={classes.title}>
						Add Payment Method
					</Typography>
				</Box>
				<Box marginTop={isMobile ? "20px" : "40px"}>
					<Typography>Credit Card</Typography>
					<Box className={classes.stripeContainer}>
						<img alt="icon" src={Pics.master} />
						<img alt="icon" src={Pics.visa} />
						<img alt="icon" src={Pics.discover} />
						<img alt="icon" src={Pics.amex} />
						<img alt="icon" src={Pics.unionPay} />
						<img alt="icon" src={Pics.JCB} />
					</Box>
				</Box>
				<Elements stripe={stripePromise}>
					<Box flexGrow={2}>
						<CheckoutForm
							callMethod={callMethod}
							set={setCallMethod}
							setIsEnabled={setIsEnabled}
							setState={(newState) => setState({ ...newState })}
						/>
						<Box className={classes.inline}>
							<CheckIcon
								className={
									default_method
										? classes.activeAvailable
										: classes.inactiveAvailable
								}
								onClick={() => setDefault(!default_method)}
							/>
							<Typography>Make default.</Typography>
						</Box>
					</Box>
					{
						showError &&
						<div style={{ width: "100%", marginTop: "10px" }}>
							<span style={{ fontFamily: "cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px' }}>*{errorMessage}</span>
						</div>
					}
					<Box className={classes.bottomBtn}>
						<Button
							style={{ width: "100%" }}
							onClick={() => setCallMethod(true)}
							disabled={!enabled || !isEnabled}
						>
							{enabled ? "Add" : "Can't add more than 10 cards"}
						</Button>
					</Box>
				</Elements>
			</Box>
		</div>
	);
}
export default withReducer("AddPayment", reducers)(withRouter(AddPayment));

import React, { useState } from "react";
// import Header from "components/home/subComponents/header";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography, withStyles, Slide, Button } from "@material-ui/core";
import Pics from "../../@assets";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as ProfileActions from "components/signup/screens/signupForm/store/actions";
// import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import { AiOutlineEdit } from 'react-icons/ai';
import StarRateIcon from "@material-ui/icons/StarRate";
import Reviews from "components/reviews";
import Carousel from "react-multi-carousel";
import moment from "moment";
// import { useCloseHamburger } from '@base'
import DrawerMenu from "components/drawerMenu";
// import enableInlineVideo from 'iphone-inline-video';
import { isIOS, isMobile } from "react-device-detect";
import DummyImage from "../../@assets/dummyImage.png"

const responsive = {
	all: {
		breakpoint: { max: 4000, min: 0 },
		items: 1,
	},
};
const useStyles = makeStyles((theme) => ({
	container: {
		backgroundSize: "cover",
		position: "relative",
		borderRadius: 6,
		backgroundColor: "#080A0A",
		padding: '60px 0 0 0'
	},
	imgContainer: {
		height: "180px",
		objectFit: 'scale-down',
		position: "relative",
		borderRadius: 6,
		"&:before": {
			display: 'none'
		}
	},
	cardContainer: {
		height: "180px",
		objectFit: 'scale-down',
		backgroundColor: "rgba(0,0,0,0.2)",
		position: "relative",
		padding: "30px 20px",
	},
	backButton: {
		position: "absolute",
		top: 10,
		left: 14,
		zIndex: 10,
		// backgroundColor: "rgba(0,0,0,0.4)",
		borderRadius: 6,
		paddingLeft: 6,
		color: "white",
		cursor: "pointer",
	},
	headerText: {
		color: '#31A7DB',
		fontSize: '1.9rem',
		fontFamily: 'Cerebri-Bold',
		position: "absolute",
		top: 25,
		left: 50,
		zIndex: 10,
		// backgroundColor: "rgba(0,0,0,0.4)",
		borderRadius: 6,
		paddingLeft: 6,
		cursor: "pointer",
	},
	editButton: {
		position: "absolute",
		top: 25,
		right: 14,
		width: "40px",
		height: "40px",
		zIndex: 10,
		// backgroundColor: "rgba(0,0,0,0.4)",
		borderRadius: 6,
		paddingLeft: 6,
		color: "#FFFFFF",
		cursor: "pointer",
	},
	main: {
		padding: "20px 20px 40px 20px",
		// [theme.breakpoints.up('sm')]: {
		// 	padding: "20px 20px 60px 20px",
		// },
		[theme.breakpoints.down('sm')]: {
			padding: "5px 20px 0px 20px",
		},
		flex: 1,
		overflow: "scroll",
		height: `${height}px`,
		// border: '1px dashed orange'
		// webkitScrollbar: {
		// 	width: "0px",
		// 	background: "transparent" /* make scrollbar transparent */
		// }
	},
	title: {
		color: "white",
		fontSize: 24,
		textTransform: "capitalize",
		fontFamily: "cerebri-regular",
		wordBreak: 'break-all',
		paddingRight: '5px'
	},
	price: {
		fontSize: 24,
	},
	dp: {
		// margin: "7px 0 0 0",
		height: "20px",
		width: "26px",
		// borderRadius: 5,
	},
	tags: {
		fontSize: 12,
		backgroundColor: "#09161C",
		fontFamily: "cerebri-regular",
		borderRadius: 6,
		padding: "5px 10px",
		margin: "0 10px 10px 0",
	},
	slider: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
		bottom: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	line: {
		marginTop: -25,
		marginRight: 10,
		height: 10,
		width: 20,
		cursor: "pointer",
	},
	video: {
		backgroundColor: "black",
		position: "absolute",
		height: "100%",
		width: "100%",
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
	},
	Bottom: {
		// height: 100,
		// backgroundImage: "linear-Bottom(#00000000, #0A0A0A)",
		// opacity: 1,
		// zIndex: 9,
		// position: "fixed",
		// borderRadius: 6,
		// width: "100%",
		// bottom: 0,
		// [theme.breakpoints.up("sm")]: {
		//   width: 370,
		//   bottom: 42,
		// },
		"&:before": {
			display: 'none'
		},
		marginTop: "-20px"
	},
	BottomTop: {
		height: 115,
		backgroundImage: "linear-Bottom(#0A0A0A,#00000000 )",
		opacity: 1,
		zIndex: 9,
		position: "fixed",
		borderRadius: 6,
		width: "100%",
		top: 0,
		[theme.breakpoints.up("sm")]: {
			width: 370,
			bottom: 42,
		},
	},
	gradientBottom: {
		// border: "1px dashed orange",
		[theme.breakpoints.up("sm")]: {
			display: "none"
		},
		width: "100%",
		height: "60px",
		position: "fixed",
		bottom: 50,
		left: 0,
		zIndex: 20,
		backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
	},
	backdrop: {
		zIndex: 100,
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '91vh',
		[theme.breakpoints.down('xs')]: {
			height: '100vh',
		},
	},
	confirmWrapper: {
		marginBottom: isIOS ? " 60px" : "40px",
		backgroundColor: '#111111',
		padding: '2rem',
		position: 'absolute',
		zIndex: 1000,
		bottom: 0,
		left: 0,
		width: '100%',
	},
	cancelHeading: {
		color: '#31A7DB',
		marginBottom: '1rem',
		fontSize: 18,
		fontFamily: 'Cerebri-SemiBold',
		fontWeight: 400
	},
	cancelConfirmText: {
		color: '#808080',
		marginBottom: '2rem',
		fontWeight: 400,
		fontSize: 16,
	},
}));
const PopupBtn = withStyles({
	root: {
		height: 42,
		'& .MuiButton-label': {
			fontSize: 18,
		},
	},
})(Button)
function viewportToPixels(value) {
	var parts = value.match(/([0-9\.]+)(vh|vw)/)
	var q = Number(parts[1])
	var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
	return side * (q / 100)
}
const height = viewportToPixels("100vh") - 510
const CustomDot = ({ active, onClick }) => {
	const classes = useStyles();
	return (
		<img
			alt="arrow"
			src={active ? Pics.blueLine : Pics.whiteLine}
			className={classes.line}
			onClick={() => onClick()}
		/>
	);
};
function Profile({ history }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
	const [date, setDate] = useState("");
	const [active, setActive] = useState(0);
	// By Shahroze \/\/\/\/\/\/\/\/\/\/
	const [profileUpdated, setProfileUpdated] = useState(false)
	// By Shahroze /\/\/\/\/\/\/\/\/\/\
	const videoRef = React.useRef(null)
	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: true }));
		dispatch(ProfileActions.updateProfile())
		// By Shahroze \/\/\/\/\/\/\/\/\/\/
		if (history.location.state === "profile-updated") {
			dispatch(BaseActions.updateLayout({ bottomNav: false }));
			setProfileUpdated(true)
			dispatch(BaseActions.updateLayout({ bottomNav: true }));
			history.location.state = ""
		}
		// By Shahroze /\/\/\/\/\/\/\/\/\/\
		// dispatch(BaseActions.getUserData());
		if (userData.created_at) {
			const test = moment(new Date(`${userData.created_at}`))
				.format("LL")
				.split(" ");
			setDate(
				test[0] +
				" " +
				(test[1].length === 2 ? `0${test[1]}` : test[1]) +
				" " +
				test[2]
			);
		}
	}, [dispatch]);

	const {
		tags,
		profile_media_urls,
		full_name,
		username,
		enabledUsername,
		rate,
		user_number_in_users,
		rating,
		bio,
		reviews_count,
		video_url,
		_id,
	} = userData;
	const handleReviews = (id, count) => {
		dispatch(
			BaseActions.showMessage(<Reviews handleClose={handleClose} count={count} userId={id} />)
		);
	};
	const handleClose = () => {
		dispatch(BaseActions.hideMessage());
	};
	function viewportToPixels(value) {
		var parts = value.match(/([0-9\.]+)(vh|vw)/)
		var q = Number(parts[1])
		var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
		return side * (q / 100)
	}
	const height = viewportToPixels("100vh") * 0.5
	const handleDrawer = () => {
		dispatch(BaseActions.hideMessage());
		dispatch(BaseActions.showMessage(<DrawerMenu />));
	};
	const [shouldUseImage, setShouldUseImage] = useState(false);
	React.useEffect(() => {
		if (isSafari() && videoRef.current) {
			// obtain reference to the video element
			const player = videoRef.current.children[0];
			// if the reference to video player has been obtained
			if (player) {
				// set the video attributes using javascript as per the
				// webkit Policy
				player.controls = true;
				player.playsinline = true;
				// player.muted = true;
				// player.setAttribute("muted", ""); // leave no stones unturned :)
				player.autoplay = false;
				// Let's wait for an event loop tick and be async.
				setTimeout(() => {
					// player.play() might return a promise but it's not guaranteed crossbrowser.
					const promise = player.play();
					// let's play safe to ensure that if we do have a promise
					if (promise.then) {
						promise
							.then(() => { })
							.catch(() => {
								// if promise fails, hide the video and fallback to <img> tag
								videoRef.current.style.display = "none";
								setShouldUseImage(true);
							});
					}
				}, 0);
			}
		}
	}, [])
	const handleStopVideo = (currentSlide) => {
		if (videoRef.current?.children[0]) {
			videoRef.current.children[0].pause()
		}
	}
	const isSafari = () => {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
	};
	// By Shahroze \/\/\/\/\/\/\/\/\/\/
	window.history.replaceState(null, '')
	// By Shahroze /\/\/\/\/\/\/\/\/\/\
	return (
		<>
			{/* <div className={classes.BottomTop}></div> */}
			<Paper className={classes.container}>
				<Box>
					{/* <ArrowBackIosIcon
						className={classes.backButton}
						onClick={() => history.push("/home")}
					/> */}
					<img
						src={"assets/Tabbar/TabbarInactive/menu.png"}
						alt={"sdfdsf"}
						className={classes.dp + " " + classes.backButton}
						onClick={() => handleDrawer()}
						style={{ marginTop: '25px' }}
					// style={{ cursor: "pointer" }}
					/>
					<Typography variant='h5' className={classes.headerText}>Profile</Typography>
					<AiOutlineEdit
						className={classes.editButton}
						style={{ padding: 5 }}
						onClick={() => history.push("/editProfile")}
					/>
				</Box>
				<div className={classes.Bottom}>
					<Carousel
						afterChange={(previousSlide, { currentSlide, onMove }) => {
							handleStopVideo(currentSlide)
						}}
						swipeable
						draggable
						responsive={responsive}
						keyBoardControl={true}
						showDots={true}
						// dotListClass={"dots-list-custom"}
						customDot={<CustomDot />}
						removeArrowOnDeviceType={["all"]}
					>
						{profile_media_urls?.map((pic, idx) => (
							pic ? <img
								key={idx}
								className={classes.imgContainer}
								alt="img"
								// src={`${pic}?${new Date().getTime()}`}
								src={`${pic === "https://narsunprojects.com:3001/uploads/default_pic.png" || pic === "" ? DummyImage : pic}`}
								draggable={false}
								width="100%"
							/> :
								<img
									key={idx}
									className={classes.imgContainer}
									alt="img"
									// src={`${pic}?${new Date().getTime()}`}
									src={`${DummyImage}`}
									draggable={false}
									width="100%"
								/>
						))}
						{video_url !== "" ? (
							<div className={classes.video}>
								{
									shouldUseImage ? (
										<img src={video_url} alt="Muted Video" />
									) : (
										<div
											ref={videoRef}
											dangerouslySetInnerHTML={{
												__html: `
											<video
											  loop
											  controls
											  style="margin-top: -40px;"
											  height="220px"
											  width="100%"
											  playsinline
											  preload="metadata"
											>
											<source src="${video_url}" type="video/mp4" />
											</video>`
											}}
										/>
									)
								}
							</div>
						) : (
							<div
								style={{
									width: "100%",
									height: 200,
									display: "flex",
									backgroundColor: "#E0E0E0",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography
									style={{
										color: "#c7c7c7",
										fontSize: 30,
									}}
								>
									No video uploaded
								</Typography>
							</div>
						)}
					</Carousel>
				</div>

				<Box style={{ height: `${height}px`, position: "relative" }} className={classes.main}>
					<Box className={classes.inline}>
						<Typography variant="h1" className={classes.title}>
							{enabledUsername ? username : full_name}
						</Typography>
						<Typography variant="body2" className={classes.price}>
							{/* {rate <= 0 ? "Free" : `$${Number(rate).toFixed(2)}`} */}
							{rate <= 0 ? "Free" : `$${Number(rate)}`}
						</Typography>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						margin={isMobile ? "5px 0" : "20px 0"}
						onClick={() => handleReviews(_id, reviews_count)}
						style={{ cursor: "pointer" }}
					>
						<StarRateIcon style={{ color: "#FCBD3F" }} />
						<Typography variant="body1">
							{rating?.toFixed(1)} ({reviews_count} reviews)
						</Typography>
					</Box>
					<Typography variant="caption">
						{bio || "Nothing to show, Please update your bio."}
					</Typography>
					<Box className={classes.inline} margin={isMobile ? "10px 0 10px" : "40px 0 20px"}>
						<Typography variant="caption">Date of signup:</Typography>
						<Typography variant="caption">{date}</Typography>
					</Box>
					<Box className={classes.inline} margin={isMobile ? "10px 0" : "20px 0"}>
						<Typography variant="caption">Member #:</Typography>
						<Typography variant="caption">{user_number_in_users}</Typography>
					</Box>
					<Box paddingBottom="10%">
						{userData.tags?.length > 0 && (
							<>
								<Box className={classes.inline} margin={isMobile ? "10px 0" : "20px 0"}>
									<Typography variant="body2">Expertise</Typography>
									<Typography variant="body2">Years</Typography>
								</Box>
								{userData.tags
									?.sort(function (a, b) {
										return b.years - a.years;
									})
									.map((t, i) => (
										<Box key={i} className={classes.inline} margin={isMobile ? "5px 0" : "10px 0"}>
											<Typography variant="caption">{t.expertise}</Typography>
											<Typography variant="caption"
											>
												{t.years}
											</Typography>
										</Box>
									))}
							</>
						)}
					</Box>
					<div className={classes.gradientBottom}></div>
				</Box>
			</Paper>
			{/* By Shahroze \/\/\/\/\/\/\/\/\/\/ */}
			<Slide direction='up' in={profileUpdated}>
				<div className={classes.backdrop} style={{ display: profileUpdated ? 'inherit' : 'none' }}>
					<div className={classes.confirmWrapper}>
						<Typography variant='subtitle1' className={classes.cancelHeading}>Profile Updated</Typography>
						<Typography variant='subtitle2' className={classes.cancelConfirmText}>You have updated your profile successfully.  <br />People can now view your photos, see your rate, and search your #tag.</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isMobile ? "80px" : '30px' }}>
							<PopupBtn
								onClick={() => setProfileUpdated(false)}
								fullWidth
								style={{ height: '50px' }}
							>
								Ok
							</PopupBtn>
						</div>
					</div>
				</div>
			</Slide>
			{/* By Shahroze \/\/\/\/\/\/\/\/\/\/ */}
		</>
	);
}
export default withRouter(Profile);